import React, { Component } from "react";
import { Fade, Container } from "react-bootstrap";
import { MenuOutlined } from "@ant-design/icons";
import { Layout, PageHeader } from "antd";

import lost_img from "../assets/img/undraw_road_sign.svg";

export default class Lost extends Component {
  render() {
    return (
      <React.Fragment>
        <Layout.Header
          className={
            this.props.navCollapsed
              ? "ant-layout-header-full-width"
              : "ant-layout-header-calc-width"
          }
        >
          <PageHeader
            title="Lost"
            backIcon={<MenuOutlined className="trigger" />}
            onBack={this.props.toggleNavCollapse}
          />
        </Layout.Header>
        <Layout.Content>
          <Fade appear={true} in={true}>
            <Container>
              <div className="put-center">
                <img src={lost_img} alt="lost" style={{ width: "70%" }} />
              </div>
            </Container>
          </Fade>
        </Layout.Content>
      </React.Fragment>
    );
  }
}
