import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Layout,
  Button,
  PageHeader,
  Space,
  Divider,
  List,
  Card,
  notification,
} from "antd";
import {
  MenuOutlined,
  ReloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Fade, Container } from "react-bootstrap";
import $ from "jquery";
import moment from "moment";

import { API_ROOT } from "../../common";

import SkillDrawer from "./components/SkillDrawer";
import EducationDrawer from "./components/EducationDrawer";
import ExperienceDrawer from "./components/ExperienceDrawer";

const dummySkillData = {
  id: 0,
  skill: "",
  order: 0,
  created_at: "",
  created_by: "",
  updated_at: "",
  updated_by: "",
};

const dummyEducationData = {
  id: "",
  order: 0,
  degree: "",
  school: "",
  major: "",
  location: "",
  start_date: null,
  graduated_date: null,
};

const dummyExperienceData = {
  id: "",
  order: 0,
  employer: "",
  title: "",
  description: "",
  location: "",
  start_date: null,
  end_date: null,
};

class Resume extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      refresh: false,
      skills: {
        fetched: false,
        progress: false,
        data: [],
        drawer: {
          show: false,
          add: false,
          data: JSON.parse(JSON.stringify(dummySkillData)),
        },
      },
      education: {
        fetched: false,
        progress: false,
        data: [],
        drawer: {
          show: false,
          add: false,
          data: JSON.parse(JSON.stringify(dummyEducationData)),
        },
      },
      experience: {
        fetched: false,
        progress: false,
        data: [],
        drawer: {
          show: false,
          add: false,
          data: JSON.parse(JSON.stringify(dummyExperienceData)),
        },
      },
    };
  }

  componentDidMount() {
    this.fetchSkills();
    this.fetchEducations();
    this.fetchExperiences();
  }

  componentDidUpdate() {
    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }
  }

  fetchSkills = () =>
    $.ajax({
      method: "GET",
      url: API_ROOT + "/rest/skills/",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        let { skills } = this.state;
        skills.fetched = true;

        if (resp.status) {
          skills.data = resp.results;
        } else {
          notification.warning({ message: resp.error || resp.message });
        }

        this.setState({ ...this.state, skills });
      },
      error: (err) => {
        console.log(err);

        let { skills } = this.state;
        skills.fetched = true;
        this.setState({ ...this.state, skills });

        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  openSkillDrawer = (data, add = false) => {
    let { skills } = this.state;

    skills.drawer.show = true;
    skills.drawer.add = add;
    skills.drawer.data = JSON.parse(JSON.stringify(data));

    this.setState({ ...this.state, skills });
  };

  closeSkillDrawer = () => {
    let { skills } = this.state;
    skills.drawer.show = false;
    this.setState({ ...this.state, skills });
  };

  clearSkillDrawer = () => {
    let { skills } = this.state;
    skills.drawer.show = false;
    skills.drawer.data = JSON.parse(JSON.stringify(dummySkillData));
    this.setState({ ...this.state, skills });
  };

  fetchEducations = () =>
    $.ajax({
      method: "GET",
      url: API_ROOT + "/rest/education/",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        let { education } = this.state;
        education.fetched = true;

        if (resp.status) {
          education.data = resp.results;
        } else {
          notification.warning({ message: resp.error || resp.message });
        }

        this.setState({ ...this.state, education });
      },
      error: (err) => {
        console.log(err);

        let { education } = this.state;
        education.fetched = true;
        this.setState({ ...this.state, education });

        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  openEducationDrawer = (data, add = false) => {
    let { education } = this.state;
    let educationData = JSON.parse(JSON.stringify(data));

    if (!add) {
      educationData.start_date = moment(data.start_date, "DD MMMM,YYYY");
      data.graduated_date
        ? (educationData.graduated_date = moment(
            data.graduated_date,
            "DD MMMM,YYYY"
          ))
        : null;
    }

    education.drawer.show = true;
    education.drawer.add = add;
    education.drawer.data = educationData;

    this.setState({ ...this.state, education });
  };

  closeEducationDrawer = () => {
    let { education } = this.state;
    education.drawer.show = false;
    this.setState({ ...this.state, education });
  };

  clearEducationDrawer = () => {
    let { education } = this.state;
    education.drawer.show = false;
    education.drawer.data = JSON.parse(JSON.stringify(dummyEducationData));
    this.setState({ ...this.state, education });
  };

  fetchExperiences = () =>
    $.ajax({
      method: "GET",
      url: API_ROOT + "/rest/experience/",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        let { experience } = this.state;
        experience.fetched = true;

        if (resp.status) {
          experience.data = resp.results;
        } else {
          notification.warning({ message: resp.error || resp.message });
        }

        this.setState({ ...this.state, experience });
      },
      error: (err) => {
        console.log(err);

        let { experience } = this.state;
        experience.fetched = true;
        this.setState({ ...this.state, experience });

        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  openExperienceDrawer = (data, add = false) => {
    let { experience } = this.state;
    let experienceData = JSON.parse(JSON.stringify(data));

    if (!add) {
      experienceData.start_date = moment(data.start_date, "DD MMMM,YYYY");
      data.end_date
        ? (experienceData.end_date = moment(data.end_date, "DD MMMM,YYYY"))
        : null;
    }

    experience.drawer.show = true;
    experience.drawer.add = add;
    experience.drawer.data = experienceData;

    this.setState({ ...this.state, experience });
  };

  closeExperienceDrawer = () => {
    let { experience } = this.state;
    experience.drawer.show = false;
    this.setState({ ...this.state, experience });
  };

  clearExperienceDrawer = () => {
    let { experience } = this.state;
    experience.drawer.show = false;
    experience.drawer.data = JSON.parse(JSON.stringify(dummyExperienceData));
    this.setState({ ...this.state, experience });
  };

  render() {
    return (
      <React.Fragment>
        <Layout.Header
          className={
            this.props.navCollapsed
              ? "ant-layout-header-full-width"
              : "ant-layout-header-calc-width"
          }
        >
          <PageHeader
            title="Resume"
            backIcon={<MenuOutlined className="trigger" />}
            onBack={this.props.toggleNavCollapse}
            extra={
              this.props.navCollapsed ||
              !window.matchMedia("(max-width: 576px)").matches ? (
                <Button
                  type="text"
                  onClick={() => {
                    this.setState({ ...this.state, refresh: true }, () => {
                      this.fetchSkills();
                      this.fetchEducations();
                      this.setState({ ...this.state, refresh: false });
                    });
                  }}
                  // loading={this.state.refresh}
                  disabled={this.state.refresh}
                >
                  {!this.state.refresh ? (
                    <ReloadOutlined />
                  ) : (
                    <LoadingOutlined />
                  )}
                </Button>
              ) : null
            }
          />
        </Layout.Header>
        <Layout.Content>
          <Fade appear={true} in={true}>
            <Container>
              <Space direction="vertical">
                <Divider>Skills</Divider>
                <Card>
                  {this.state.skills.fetched ? (
                    <Space direction="vertical">
                      <div className="put-center">
                        <Button
                          type="primary"
                          ghost
                          size="small"
                          onClick={() =>
                            this.openSkillDrawer(dummySkillData, true)
                          }
                        >
                          Add
                        </Button>
                      </div>
                      {this.state.skills.data.length === 0 ? (
                        <Empty description="No Skills" />
                      ) : (
                        <List
                          bordered
                          dataSource={this.state.skills.data}
                          renderItem={(skill) => (
                            <List.Item
                              onClick={() => this.openSkillDrawer(skill)}
                            >
                              <div className="clickable put-between w-100">
                                {skill.skill}
                                <small>{skill.updated_at}</small>
                              </div>
                            </List.Item>
                          )}
                        />
                      )}
                    </Space>
                  ) : (
                    <div className="p-5 text-center">
                      <h6 className="put-center">
                        <LoadingOutlined className="mr-2" /> Fetching
                      </h6>
                    </div>
                  )}
                </Card>
                <Divider>Education</Divider>
                <Card>
                  {this.state.education.fetched ? (
                    <Space direction="vertical">
                      <div className="put-center">
                        <Button
                          type="primary"
                          ghost
                          size="small"
                          onClick={() =>
                            this.openEducationDrawer(dummyEducationData, true)
                          }
                        >
                          Add
                        </Button>
                      </div>
                      {this.state.education.data.length === 0 ? (
                        <Empty description="No Education Data" />
                      ) : (
                        <List
                          bordered
                          dataSource={this.state.education.data}
                          renderItem={(education) => (
                            <List.Item
                              onClick={() =>
                                this.openEducationDrawer(education)
                              }
                            >
                              <div className="clickable put-between w-100">
                                {education.degree} ({education.school})
                                <small>{education.updated_at}</small>
                              </div>
                            </List.Item>
                          )}
                        />
                      )}
                    </Space>
                  ) : (
                    <div className="p-5 text-center">
                      <h6 className="put-center">
                        <LoadingOutlined className="mr-2" /> Fetching
                      </h6>
                    </div>
                  )}
                </Card>
                <Divider>Experience</Divider>
                <Card>
                  {this.state.experience.fetched ? (
                    <Space direction="vertical">
                      <div className="put-center">
                        <Button
                          type="primary"
                          ghost
                          size="small"
                          onClick={() =>
                            this.openExperienceDrawer(dummyExperienceData, true)
                          }
                        >
                          Add
                        </Button>
                      </div>
                      {this.state.experience.data.length === 0 ? (
                        <Empty description="No Experience Data" />
                      ) : (
                        <List
                          bordered
                          dataSource={this.state.experience.data}
                          renderItem={(experience) => (
                            <List.Item
                              onClick={() =>
                                this.openExperienceDrawer(experience)
                              }
                            >
                              <div className="clickable put-between w-100">
                                {experience.title} ({experience.employer})
                                <small>{experience.updated_at}</small>
                              </div>
                            </List.Item>
                          )}
                        />
                      )}
                    </Space>
                  ) : (
                    <div className="p-5 text-center">
                      <h6 className="put-center">
                        <LoadingOutlined className="mr-2" /> Fetching
                      </h6>
                    </div>
                  )}
                </Card>
              </Space>
            </Container>
          </Fade>
          <SkillDrawer
            fetch={this.fetchSkills}
            close={this.closeSkillDrawer}
            clear={this.clearSkillDrawer}
            data={this.state.skills.drawer.data}
            add={this.state.skills.drawer.add}
            show={this.state.skills.drawer.show}
          />
          <EducationDrawer
            fetch={this.fetchEducations}
            close={this.closeEducationDrawer}
            clear={this.clearEducationDrawer}
            data={this.state.education.drawer.data}
            add={this.state.education.drawer.add}
            show={this.state.education.drawer.show}
          />
          <ExperienceDrawer
            fetch={this.fetchExperiences}
            close={this.closeExperienceDrawer}
            clear={this.clearExperienceDrawer}
            data={this.state.experience.drawer.data}
            add={this.state.experience.drawer.add}
            show={this.state.experience.drawer.show}
          />
        </Layout.Content>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
});

export default connect(mapStateToProps, {})(Resume);
