import React, { Component } from "react";
import { Fade, Container } from "react-bootstrap";
import {
  Layout,
  PageHeader,
  Form,
  Input,
  Button,
  Space,
  notification,
} from "antd";
import {
  LoadingOutlined,
  MenuOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import $ from "jquery";

import { API_ROOT } from "../../common";

const dummyData = {
  id: 0,
  email: "",
  address: "",
  bio: "",
  created_at: "",
  created_by: "",
  updated_at: "",
  updated_by: "",
};

class Personal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      fetched: false,
      progress: false,
      data: JSON.parse(JSON.stringify(dummyData)),
    };
  }

  componentDidMount() {
    this.fetchPersonalData();
  }

  componentDidUpdate() {
    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }
  }

  fetchPersonalData = () =>
    $.ajax({
      url: API_ROOT + "/rest/personal/",
      method: "GET",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        if (resp.status) {
          this.setState({
            ...this.state,
            fetched: true,
            progress: false,
            data: resp.results,
          });
        } else {
          this.setState({ ...this.state, fetched: true, progress: false }, () =>
            notification.warning({ message: resp.error || resp.message })
          );
        }
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  submitForm = () =>
    $.ajax({
      url: API_ROOT + "/rest/personal/",
      method: "POST",
      headers: { Authorization: this.state.access },
      data: {
        email: this.state.data.email,
        address: this.state.data.address,
        bio: this.state.data.bio,
      },
      success: (resp) => {
        if (resp.status) {
          this.setState(
            { ...this.state, progress: false, data: resp.results },
            () => notification.success({ message: resp.message })
          );
        } else {
          this.setState({ ...this.state, progress: false }, () =>
            notification.warning({ message: resp.error || resp.message })
          );
        }
      },
      error: (err) => {
        console.log(err);
        this.setState({ ...this.state, progress: false }, () =>
          notification.error({
            message: err.responseJSON
              ? err.responseJSON.error || err.responseJSON.message
              : "Something went wrong.",
          })
        );
      },
    });

  render() {
    return (
      <React.Fragment>
        <Layout.Header
          className={
            this.props.navCollapsed
              ? "ant-layout-header-full-width"
              : "ant-layout-header-calc-width"
          }
        >
          <PageHeader
            title="Personal"
            backIcon={<MenuOutlined className="trigger" />}
            onBack={this.props.toggleNavCollapse}
            extra={
              this.props.navCollapsed ||
              !window.matchMedia("(max-width: 576px)").matches ? (
                <Button
                  type="text"
                  // loading={this.state.progress}
                  disabled={this.state.progress || !this.state.fetched}
                  onClick={() =>
                    this.setState(
                      { ...this.state, progress: true },
                      this.fetchPersonalData
                    )
                  }
                >
                  {!this.state.progress ? (
                    <ReloadOutlined />
                  ) : (
                    <LoadingOutlined />
                  )}
                </Button>
              ) : null
            }
          />
        </Layout.Header>
        <Layout.Content>
          <Fade appear={true} in={true}>
            <Container>
              <Form
                name="personal-form"
                layout="vertical"
                fields={[
                  {
                    name: "email",
                    value: this.state.data.email,
                  },
                  {
                    name: "address",
                    value: this.state.data.address,
                  },
                  {
                    name: "bio",
                    value: this.state.data.bio,
                  },
                ]}
                onValuesChange={(e) => {
                  let { data } = this.state;

                  let key = Object.keys(e)[0];

                  data[key] = e[key];

                  this.setState({ ...this.state, data });
                }}
                onFinish={() =>
                  this.setState(
                    { ...this.state, progress: true },
                    this.submitForm
                  )
                }
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Enter Email!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    type="email"
                    disabled={!this.state.fetched}
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[
                    {
                      required: true,
                      message: "Enter Address!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Address"
                    allowClear
                    disabled={!this.state.fetched}
                  />
                </Form.Item>
                <Form.Item
                  name="bio"
                  label="Bio"
                  rules={[
                    {
                      required: true,
                      message: "Enter Bio!",
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Bio"
                    rows="15"
                    disabled={!this.state.fetched}
                  />
                </Form.Item>
                <div className="text-center">
                  <Space direction="vertical">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.progress}
                      disabled={this.state.progress || !this.state.fetched}
                    >
                      Submit
                    </Button>
                    <small>{this.state.data.updated_at}</small>
                  </Space>
                </div>
              </Form>
              {!this.state.fetched ? (
                <div className="p-5 text-center">
                  <h5 className="put-center">
                    <LoadingOutlined className="mr-1" /> Fetching
                  </h5>
                </div>
              ) : null}
            </Container>
          </Fade>
        </Layout.Content>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
});

export default connect(mapStateToProps, {})(Personal);
