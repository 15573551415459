import React, { Component } from "react";
import { Fade, Container } from "react-bootstrap";
import {
  Layout,
  PageHeader,
  Form,
  Input,
  Button,
  Divider,
  List,
  Card,
  Space,
  Empty,
  notification,
} from "antd";
import {
  MenuOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import $ from "jquery";

import { API_ROOT } from "../../common";

import LinkDrawer from "./components/LinkDrawer";

const dummyLandingData = {
  id: 0,
  header: "",
  short_description: "",
  long_description: "",
  created_at: "",
  created_by: "",
  updated_at: "",
  updated_by: "",
};

const dummyLinkData = {
  id: 0,
  name: "",
  link: "",
  icon: "",
  order: 0,
  created_at: "",
  created_by: "",
  updated_at: "",
  updated_by: "",
};

class Basic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      refresh: false,
      landing_description: {
        fetched: false,
        progress: false,
        data: dummyLandingData,
      },
      links: {
        fetched: false,
        progress: false,
        data: [],
        drawer: {
          show: false,
          add: false,
          data: JSON.parse(JSON.stringify(dummyLinkData)),
        },
      },
    };
  }

  componentDidMount() {
    this.fetchLandingDescription();
    this.fetchLinks();
  }

  componentDidUpdate() {
    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }
  }

  fetchLandingDescription = () =>
    $.ajax({
      method: "GET",
      url: API_ROOT + "/rest/landing-description/",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        let { landing_description } = this.state;
        landing_description.fetched = true;

        if (resp.status) {
          landing_description.data = resp.results;
        } else {
          notification.warning({ message: resp.error || resp.message });
        }

        this.setState({ ...this.state, landing_description });
      },
      error: (err) => {
        console.log(err);

        let { landing_description } = this.state;
        landing_description.fetched = true;
        this.setState({ ...this.state, landing_description });

        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  fetchLinks = () =>
    $.ajax({
      method: "GET",
      url: API_ROOT + "/rest/social-links/",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        let { links } = this.state;
        links.fetched = true;

        if (resp.status) {
          links.data = resp.results;
        } else {
          notification.warning({ message: resp.error || resp.message });
        }

        this.setState({ ...this.state, links });
      },
      error: (err) => {
        console.log(err);

        let { links } = this.state;
        links.fetched = true;
        this.setState({ ...this.state, links });

        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  submitLandingDescription = () =>
    $.ajax({
      method: "POST",
      url: API_ROOT + "/rest/landing-description/",
      headers: { Authorization: this.state.access },
      data: {
        header: this.state.landing_description.data.header,
        short_description:
          this.state.landing_description.data.short_description,
        long_description: this.state.landing_description.data.long_description,
      },
      success: (resp) => {
        let { landing_description } = this.state;
        landing_description.progress = false;

        if (resp.status) {
          landing_description.data = resp.results;
          notification.success({ message: resp.message });
        } else {
          notification.warning({ message: resp.error || resp.message });
        }

        this.setState({ ...this.state, landing_description });
      },
      error: (err) => {
        console.log(err);

        let { landing_description } = this.state;
        landing_description.progress = false;
        this.setState({ ...this.state, landing_description });

        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  openLinkDrawer = (data, add = false) => {
    let { links } = this.state;

    links.drawer.show = true;
    links.drawer.add = add;
    links.drawer.data = JSON.parse(JSON.stringify(data));

    this.setState({ ...this.state, links });
  };

  closeLinkDrawer = () => {
    let { links } = this.state;
    links.drawer.show = false;
    this.setState({ ...this.state, links });
  };

  clearLinkDrawer = () => {
    let { links } = this.state;
    links.drawer.show = false;
    links.drawer.data = JSON.parse(JSON.stringify(dummyLinkData));
    this.setState({ ...this.state, links });
  };

  render() {
    return (
      <React.Fragment>
        <Layout.Header
          className={
            this.props.navCollapsed
              ? "ant-layout-header-full-width"
              : "ant-layout-header-calc-width"
          }
        >
          <PageHeader
            title="Basic Stuffs"
            backIcon={<MenuOutlined className="trigger" />}
            onBack={this.props.toggleNavCollapse}
            extra={
              this.props.navCollapsed ||
              !window.matchMedia("(max-width: 576px)").matches ? (
                <Button
                  type="text"
                  onClick={() => {
                    this.setState({ ...this.state, refresh: true }, () => {
                      this.fetchLandingDescription();
                      this.fetchSkills();
                      this.fetchLinks();

                      this.setState({ ...this.state, refresh: false });
                    });
                  }}
                  // loading={this.state.refresh}
                  disabled={this.state.refresh}
                >
                  {!this.state.refresh ? (
                    <ReloadOutlined />
                  ) : (
                    <LoadingOutlined />
                  )}
                </Button>
              ) : null
            }
          />
        </Layout.Header>
        <Layout.Content>
          <Fade appear={true} in={true}>
            <Container>
              <Space direction="vertical">
                <Divider>Landing Description</Divider>
                <Form
                  name="landing-description"
                  className="w-100"
                  layout="vertical"
                  fields={[
                    {
                      name: "header",
                      value: this.state.landing_description.data.header,
                    },
                    {
                      name: "short_description",
                      value:
                        this.state.landing_description.data.short_description,
                    },
                    {
                      name: "long_description",
                      value:
                        this.state.landing_description.data.long_description,
                    },
                  ]}
                  onValuesChange={(e) => {
                    let { landing_description } = this.state;

                    let key = Object.keys(e)[0];

                    landing_description.data[key] = e[key];

                    this.setState({ ...this.state, landing_description });
                  }}
                  onFinish={() => {
                    let { landing_description } = this.state;
                    landing_description.progress = true;
                    this.setState(
                      { ...this.state, landing_description },
                      this.submitLandingDescription
                    );
                  }}
                >
                  <Form.Item
                    name="header"
                    label="Header"
                    rules={[
                      {
                        required: true,
                        message: "Enter Header!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Cool Header"
                      disabled={!this.state.landing_description.fetched}
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    name="short_description"
                    label="Short Description"
                    rules={[
                      {
                        required: true,
                        message: "Enter Short Description!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Cool Short Description"
                      disabled={!this.state.landing_description.fetched}
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    name="long_description"
                    label="Long Description"
                    rules={[
                      {
                        required: true,
                        message: "Enter Long Description!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Cool Long Description"
                      rows="5"
                      disabled={!this.state.landing_description.fetched}
                      allowClear
                    />
                  </Form.Item>
                  <div className="put-between">
                    <small>
                      {this.state.landing_description.data.updated_at}
                    </small>
                    {!this.state.landing_description.fetched ? (
                      <div>
                        <h6 className="put-center">
                          <LoadingOutlined className="mr-2" /> Fetching
                        </h6>
                      </div>
                    ) : null}
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.landing_description.progress}
                      disabled={
                        !this.state.landing_description.fetched ||
                        this.state.landing_description.progress
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
                <Divider>Social Links</Divider>
                <Card>
                  {this.state.links.fetched ? (
                    <Space direction="vertical">
                      <div className="put-center">
                        <Button
                          type="primary"
                          ghost
                          size="small"
                          onClick={() =>
                            this.openLinkDrawer(dummyLinkData, true)
                          }
                        >
                          Add
                        </Button>
                      </div>
                      {this.state.links.data.length === 0 ? (
                        <Empty description="No Social Links" />
                      ) : (
                        <List
                          bordered
                          dataSource={this.state.links.data}
                          renderItem={(link) => (
                            <List.Item
                              onClick={() => this.openLinkDrawer(link)}
                            >
                              <div className="clickable put-between w-100">
                                <div>
                                  <i className={link.icon} /> {link.name}
                                </div>
                                <small>{link.updated_at}</small>
                              </div>
                            </List.Item>
                          )}
                        />
                      )}
                    </Space>
                  ) : (
                    <div className="p-5 text-center">
                      <h6 className="put-center">
                        <LoadingOutlined className="mr-2" /> Fetching
                      </h6>
                    </div>
                  )}
                </Card>
              </Space>
            </Container>
          </Fade>
          <LinkDrawer
            fetch={this.fetchLinks}
            close={this.closeLinkDrawer}
            clear={this.clearLinkDrawer}
            data={this.state.links.drawer.data}
            add={this.state.links.drawer.add}
            show={this.state.links.drawer.show}
          />
        </Layout.Content>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
});

export default connect(mapStateToProps, {})(Basic);
