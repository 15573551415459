import React, { Component } from "react";
import { Fade, Container } from "react-bootstrap";
import {
  Layout,
  PageHeader,
  Form,
  Input,
  DatePicker,
  Button,
  List,
  Select,
  Empty,
  Card,
  Space,
  notification,
} from "antd";
import {
  MenuOutlined,
  SearchOutlined,
  LoadingOutlined,
  LeftOutlined,
  RightOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import $ from "jquery";
import moment from "moment";

import { API_ROOT } from "../../common";

import Message from "../components/Message";

const dummyData = {
  id: 0,
  name: "",
  email: "",
  message: "",
  read: true,
  summary: "",
  created_at: "",
  updated_at: "",
};

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      fetched: false,
      progress: false,
      messages: [],
      markedMessages: [],
      search: {
        date: "",
        name: "",
        email: "",
        message: "",
        read: "",
      },
      drawer: {
        show: false,
        data: JSON.parse(JSON.stringify(dummyData)),
      },
      pagination: {
        count: "",
        next: "",
        previous: "",
        totalPage: "",
        activePage: 1,
        progress: {
          next: false,
          prev: false,
        },
      },
    };
  }

  componentDidMount() {
    this.fetchMessages();
  }

  componentDidUpdate() {
    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }
  }

  fetchMessages = (paginate = null) => {
    let url =
      API_ROOT + "/rest/messages/?page=" + this.state.pagination.activePage;

    if (paginate === "next") {
      let { pagination } = this.state;
      pagination.progress.next = true;
      this.setState({ ...this.state, pagination });

      url = this.state.pagination.next;
    } else if (paginate === "prev") {
      let { pagination } = this.state;
      pagination.progress.prev = true;
      this.setState({ ...this.state, pagination });

      url = this.state.pagination.previous;
    }

    $.ajax({
      url,
      method: "GET",
      headers: { Authorization: this.state.access },
      data: {
        name: this.state.search.name,
        email: this.state.search.email,
        message: this.state.search.message,
        date: this.state.search.date ? this.state.search.date._i : "",
        read: this.state.search.read,
      },
      success: (resp) => {
        let { pagination } = this.state;
        pagination.progress.next = false;
        pagination.progress.prev = false;

        if (resp.status) {
          pagination.count = resp.count;
          pagination.next = resp.next;
          pagination.previous = resp.previous;

          let total = pagination.count / 10;
          let integer = Math.floor(total);

          if (total > integer) {
            total = integer + 1;
          } else {
            total = integer;
          }

          pagination.totalPage = total;

          if (resp.next) {
            let nextPage = resp.next
              .split("?")[1] // split the url at '?'
              .split("&") // split the last item at '&'
              .filter((items) => items.indexOf("page=") !== -1)[0] // take the string containing 'page='
              .split("=")[1]; // split that string at '=' and the last item is next page
            pagination.activePage = parseInt(nextPage) - 1;
          } else if (resp.previous) {
            if (resp.previous.indexOf("page=") === -1) {
              pagination.activePage = 2;
            } else {
              let prevPage = resp.previous
                .split("?")[1] // split the url at '?'
                .split("&") // split the last item at '&'
                .filter((items) => items.indexOf("page=") !== -1)[0] // take the item with 'page='
                .split("=")[1]; // split that string at '=' and the last item is prev page

              let previousPage = parseInt(prevPage);

              pagination.activePage = previousPage + 1;
            }
          } else {
            pagination.activePage = 1;
          }

          this.setState({
            ...this.state,
            progress: false,
            fetched: true,
            messages: resp.results,
            pagination,
          });
        } else {
          notification.warning({ message: resp.error || resp.message });
          this.setState({
            ...this.state,
            progress: false,
            fetched: true,
            pagination,
          });
        }
      },
      error: (err) => {
        console.log(err);

        let { pagination } = this.state;
        pagination.progress.next = false;
        pagination.progress.prev = false;
        this.setState({
          ...this.state,
          pagination,
          progress: false,
          fetched: true,
        });

        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });
  };

  markAsRead = (messages = [], notify = true) => {
    if (messages.length === 0) {
      messages = this.state.markedMessages;
    }

    $.ajax({
      url: API_ROOT + "/rest/messages/",
      method: "POST",
      headers: { Authorization: this.state.access },
      data: {
        messages: JSON.stringify(messages),
      },
      success: (resp) => {
        if (resp.status) {
          if (notify) {
            notification.success({ message: resp.message });
          }

          this.fetchMessages();
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
      },
      error: (err) => {
        console.log(err);

        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });
  };

  openMessageDrawer = (data) => {
    let { drawer } = this.state;
    drawer.show = true;
    drawer.data = JSON.parse(JSON.stringify(data));
    this.setState({ ...this.state, drawer });
  };

  closeMessageDrawer = () => {
    let { drawer } = this.state;
    drawer.show = false;
    this.setState({ ...this.state, drawer });
  };

  clearMessageDrawer = () => {
    let { drawer } = this.state;
    drawer.show = false;
    drawer.data = JSON.parse(JSON.stringify(dummyData));
    this.setState({ ...this.state, drawer });
  };

  render() {
    return (
      <React.Fragment>
        <Layout.Header
          className={
            this.props.navCollapsed
              ? "ant-layout-header-full-width"
              : "ant-layout-header-calc-width"
          }
        >
          <PageHeader
            title="Messages"
            backIcon={<MenuOutlined className="trigger" />}
            onBack={this.props.toggleNavCollapse}
            extra={
              this.props.navCollapsed ||
              !window.matchMedia("(max-width: 576px)").matches ? (
                <Button
                  type="text"
                  onClick={() =>
                    this.setState(
                      {
                        ...this.state,
                        progress: true,
                        search: {
                          date: "",
                          name: "",
                          email: "",
                          message: "",
                          read: "",
                        },
                      },
                      this.fetchMessages
                    )
                  }
                  disabled={this.state.progress || !this.state.fetched}
                >
                  {this.state.progress ? (
                    <LoadingOutlined />
                  ) : (
                    <ReloadOutlined />
                  )}
                </Button>
              ) : null
            }
          />
        </Layout.Header>
        <Layout.Content>
          <Fade appear={true} in={true}>
            <Container>
              <Form
                name="search-message"
                fields={[
                  {
                    name: "name",
                    value: this.state.search.name,
                  },
                  {
                    name: "email",
                    value: this.state.search.email,
                  },
                  {
                    name: "message",
                    value: this.state.search.message,
                  },
                  {
                    name: "date",
                    value: this.state.search.date,
                  },
                ]}
                onValuesChange={(e) => {
                  let { search } = this.state;

                  let key = Object.keys(e)[0];

                  if (key === "date" && e[key]) {
                    search[key] = moment(
                      `${e[key]._d.getFullYear()}-${String(
                        e[key]._d.getMonth() + 1
                      ).padStart(2, "0")}-${String(
                        e[key]._d.getDate()
                      ).padStart(2, "0")}`,
                      "YYYY-MM-DD"
                    );
                  } else {
                    search[key] = e[key];
                  }

                  this.setState({ ...this.state, search });
                }}
                onFinish={() =>
                  this.setState(
                    { ...this.state, progress: true },
                    this.fetchMessages
                  )
                }
              >
                <Input.Group className="text-center" compact>
                  <Form.Item style={{ width: "23%" }} name="name">
                    <Input allowClear placeholder="Name" />
                  </Form.Item>
                  <Form.Item style={{ width: "23%" }} name="email">
                    <Input allowClear placeholder="Email" />
                  </Form.Item>
                  <Form.Item style={{ width: "23%" }} name="message">
                    <Input
                      allowClear
                      placeholder="Hint of something in message"
                    />
                  </Form.Item>
                  <Form.Item style={{ width: "23%" }} name="date">
                    <DatePicker
                      className="w-100"
                      format="YYYY-MM-DD"
                      allowClear
                      placeholder="Date"
                    />
                  </Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    style={{ width: "7%" }}
                    icon={<SearchOutlined />}
                    disabled={!this.state.fetched || this.state.progress}
                  />
                </Input.Group>
              </Form>
              <div className="text-right">
                <Select
                  className="text-center"
                  style={{
                    width: window.matchMedia("(max-width: 576px)").matches
                      ? "100%"
                      : "100px",
                  }}
                  value={this.state.search.read}
                  onChange={(e) => {
                    let { search } = this.state;
                    search.read = e;
                    this.setState(
                      { ...this.state, search },
                      this.fetchMessages
                    );
                  }}
                >
                  <Select.Option value="">All</Select.Option>
                  <Select.Option value={true}>Read</Select.Option>
                  <Select.Option value={false}>Unread</Select.Option>
                </Select>
              </div>
              <hr />
              {this.state.fetched ? (
                this.state.messages.length === 0 ? (
                  <Empty className="p-5" description="No Messages." />
                ) : (
                  <Space direction="vertical">
                    <Card>
                      <List
                        bordered
                        dataSource={this.state.messages}
                        renderItem={(message) => (
                          <List.Item
                            className={
                              message.read ? "clickable" : "clickable bg-unread"
                            }
                            onClick={() => {
                              this.markAsRead([message.id], false);
                              this.openMessageDrawer(message);
                            }}
                          >
                            <div className="put-between w-100">
                              <List.Item.Meta
                                title={message.summary}
                                description={<small>{message.email}</small>}
                              />
                              <small>{message.created_at}</small>
                            </div>
                          </List.Item>
                        )}
                      />
                    </Card>
                    <div className="put-between">
                      <Button
                        type="text"
                        onClick={() => this.fetchMessages("prev")}
                        disabled={
                          this.state.pagination.previous === null ||
                          this.state.pagination.progress.prev
                        }
                      >
                        {this.state.pagination.progress.prev ? (
                          <LoadingOutlined />
                        ) : (
                          <LeftOutlined />
                        )}
                        Previous
                      </Button>
                      <div>
                        <small>
                          {this.state.pagination.activePage} of{" "}
                          {this.state.pagination.totalPage}
                        </small>
                      </div>
                      <Button
                        type="text"
                        onClick={() => this.fetchMessages("next")}
                        disabled={
                          this.state.pagination.next === null ||
                          this.state.pagination.progress.next
                        }
                      >
                        Next
                        {this.state.pagination.progress.next ? (
                          <LoadingOutlined />
                        ) : (
                          <RightOutlined />
                        )}
                      </Button>
                    </div>
                  </Space>
                )
              ) : (
                <div className="p-5 text-center">
                  <h5 className="put-center">
                    <LoadingOutlined className="mr-1" /> Fetching
                  </h5>
                </div>
              )}
            </Container>
          </Fade>
          <Message
            show={this.state.drawer.show}
            data={this.state.drawer.data}
            close={this.closeMessageDrawer}
            clear={this.clearMessageDrawer}
          />
        </Layout.Content>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
});

export default connect(mapStateToProps, {})(Messages);
