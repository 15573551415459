import { Line } from "@ant-design/charts";

export default function TrafficChart(props) {
  let data = props.data;

  let config = {
    data,
    xField: "date",
    yField: "value",
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return "".concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
            return "".concat(s, ",");
          });
        },
      },
    },
    color: ["#008ae6"],
  };

  return <Line {...config} />;
}
