import {
  SET_AUTH,
  SET_EXPIRY,
  SET_USER,
  SET_ACTIVE_PORTFOLIO_TAB,
  SET_TRAFFIC_DATA,
  SET_TRAFFIC_DATE_MONTH,
  SET_TRAFFIC_DATE_YEAR,
} from "./types";

// AUTH ACTIONS

export const setAuth = (auth) => (dispatch) => {
  dispatch({
    type: SET_AUTH,
    auth,
  });
};

export const setExpiry = (expiry) => (dispatch) => {
  dispatch({
    type: SET_EXPIRY,
    expiry,
  });
};

export const logOut = () => (dispatch) => {
  localStorage.removeItem("state");

  dispatch({
    type: SET_AUTH,
    auth: { access: null, refresh: null, user: null },
  });
};

// USER ACTIONS

export const setUser = (user) => (dispatch) => {
  dispatch({
    type: SET_USER,
    user,
  });
};

// PORTFOLIO ACTIONS

export const setActivePortfolioTab = (tab) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_PORTFOLIO_TAB,
    tab,
  });
};

// TRAFFIC ACTIONS

export const setTrafficData = (data) => (dispatch) => {
  dispatch({
    type: SET_TRAFFIC_DATA,
    data,
  });
};

export const setTrafficDateMonth = (month) => (dispatch) => {
  dispatch({
    type: SET_TRAFFIC_DATE_MONTH,
    month,
  });
};

export const setTrafficDateYear = (year) => (dispatch) => {
  dispatch({
    type: SET_TRAFFIC_DATE_YEAR,
    year,
  });
};
