import React, { Component } from "react";
import {
  Drawer,
  Form,
  Input,
  Button,
  Space,
  Row,
  Col,
  Popconfirm,
  notification,
} from "antd";
import { connect } from "react-redux";
import $ from "jquery";

import { API_ROOT } from "../../../common";

class SkillDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      progress: false,
      show: this.props.show,
      data: this.props.data,
    };

    this.form = React.createRef();
  }

  componentDidUpdate() {
    if (this.state.data !== this.props.data) {
      this.setState({ ...this.state, data: this.props.data });
    }

    if (this.state.show !== this.props.show) {
      this.setState({ ...this.state, show: this.props.show });
    }

    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }
  }

  submitForm = () => {
    this.setState({ ...this.state, progress: true });

    let { data } = this.state;
    let url;
    let method;

    if (this.props.add) {
      url = API_ROOT + "/rest/skills/";
      method = "POST";
    } else {
      url = API_ROOT + `/rest/skills/${data.id}/`;
      method = "PUT";
    }

    $.ajax({
      url,
      method,
      headers: { Authorization: this.state.access },
      data: {
        order: data.order,
        skill: data.skill,
      },
      success: (resp) => {
        this.setState({ ...this.state, progress: false });

        if (resp.status) {
          notification.success({ message: resp.message });
          this.props.fetch();
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
      },
      error: (err) => {
        console.log(err);
        this.setState({ ...this.state, progress: false });
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });
  };

  deleteSkill = () => {
    this.setState({ ...this.state, progress: true });

    let { data } = this.state;

    $.ajax({
      url: API_ROOT + `/rest/skills/${data.id}/`,
      method: "DELETE",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        this.setState({ ...this.state, progress: false });

        if (resp.status) {
          notification.success({ message: resp.message });
          this.props.close();
          this.props.fetch();
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
      },
      error: (err) => {
        console.log(err);
        this.setState({ ...this.state, progress: false });
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });
  };

  render() {
    return (
      <Drawer
        title={
          this.props.add ? "Add New Skill" : `Skill ${this.state.data.skill}`
        }
        visible={this.state.show}
        closable={!this.state.progress}
        keyboard={!this.state.progress}
        maskClosable={!this.state.progress}
        height={window.matchMedia("(max-width: 576px)").matches ? "45%" : "30%"}
        placement="bottom"
        onClose={this.props.close}
        afterVisibleChange={(visible) => {
          if (!visible) {
            this.props.clear();
            this.form.current.resetFields();
          }
        }}
        footer={
          <Space className="put-center">
            <Button
              type="primary"
              onClick={() => $("#skill-form-btn").trigger("click")}
              loading={this.state.progress}
              disabled={this.state.progress}
            >
              Submit
            </Button>
            {!this.props.add ? (
              <Popconfirm
                title="Delete this skill?"
                placement="top"
                onConfirm={this.deleteSkill}
                okText="Yes"
                cancelText="No"
                okType="danger"
                disabled={this.state.progress}
              >
                <Button type="primary" danger disabled={this.state.progress}>
                  Delete
                </Button>
              </Popconfirm>
            ) : null}
          </Space>
        }
      >
        <Form
          name="skill-form"
          ref={this.form}
          layout="vertical"
          fields={[
            {
              name: "skill",
              value: this.state.data.skill,
            },
            {
              name: "order",
              value: this.state.data.order,
            },
          ]}
          onValuesChange={(e) => {
            let { data } = this.state;

            let key = Object.keys(e)[0];

            data[key] = e[key];

            this.setState({ ...this.state, data });
          }}
          onFinish={this.submitForm}
        >
          <button
            style={{ display: "none" }}
            type="submit"
            id="skill-form-btn"
          ></button>
          <Row gutter={[5, 5]}>
            <Col sm={12}>
              <Form.Item
                label="Order"
                name="order"
                rules={[
                  {
                    required: true,
                    message: "Enter Skill Order!",
                  },
                ]}
              >
                <Input placeholder="Order" type="number" />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                label="Skill"
                name="skill"
                rules={[
                  {
                    required: true,
                    message: "Enter Skill Name!",
                  },
                ]}
              >
                <Input placeholder="Skill" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
});

export default connect(mapStateToProps, {})(SkillDrawer);
