import React, { Component } from "react";
import {
  Modal,
  Space,
  Button,
  Popconfirm,
  Input,
  Form,
  InputNumber,
  Row,
  Col,
  notification,
} from "antd";
import { connect } from "react-redux";
import $ from "jquery";

import { API_ROOT } from "../../../common";

class ImageModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: false,
      show: this.props.show,
      data: this.props.data,
    };
  }

  componentDidUpdate() {
    if (this.state.show !== this.props.show) {
      this.setState({ ...this.state, show: this.props.show });
    }

    if (this.state.data !== this.props.data) {
      this.setState({ ...this.state, data: this.props.data });
    }
  }

  submitForm = () =>
    $.ajax({
      url: API_ROOT + `/rest/portfolio-image/${this.state.data.id}/`,
      method: "PUT",
      headers: { Authorization: this.props.access },
      data: {
        name: this.state.data.image_name,
        order: this.state.data.order,
      },
      success: (resp) => {
        this.setState({ ...this.state, progress: false });

        if (resp.status) {
          notification.success({ message: resp.message });
          this.props.fetch();
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
        this.setState({ ...this.state, progress: false });
      },
    });

  deleteImage = () =>
    $.ajax({
      url: API_ROOT + `/rest/portfolio-image/${this.state.data.id}/`,
      method: "DELETE",
      headers: { Authorization: this.props.access },
      success: (resp) => {
        this.setState({ ...this.state, progress: false });

        if (resp.status) {
          notification.success({ message: resp.message });
          this.props.fetch();
          this.props.close();
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
        this.setState({ ...this.state, progress: false });
      },
    });

  render() {
    return (
      <Modal
        title={`${this.state.data.image_name}.${this.state.data.image_format}`}
        width="90%"
        visible={this.state.show}
        afterClose={this.props.clear}
        onCancel={this.props.close}
        closable={!this.state.progress}
        keyboard={!this.state.progress}
        maskClosable={!this.state.progress}
        footer={
          <Space className="put-center" wrap>
            <Button
              type="primary"
              onClick={() => $("#image-submit-form").trigger("click")}
              disabled={this.state.progress}
              loading={this.state.progress}
            >
              Submit
            </Button>
            <Popconfirm
              title="Delete this image?"
              okText="Yes"
              cancelText="No"
              okType="danger"
              onConfirm={this.deleteImage}
              disabled={this.state.progress}
            >
              <Button
                type="primary"
                danger
                ghost
                disabled={this.state.progress}
              >
                Delete
              </Button>
            </Popconfirm>
          </Space>
        }
        centered
      >
        <Row gutter={[5, 5]}>
          <Col md={16}>
            <img
              src={this.state.data.image}
              alt={this.state.data.image_name}
              style={{ width: "100%" }}
            />
          </Col>
          <Col md={8}>
            <Form
              name="image-form"
              layout="vertical"
              fields={[
                {
                  name: "order",
                  value: this.state.data.order,
                },
                {
                  name: "image_name",
                  value: this.state.data.image_name,
                },
              ]}
              onValuesChange={(e) => {
                let { data } = this.state;

                let key = Object.keys(e)[0];

                data[key] = e[key];

                this.setState({ ...this.state, data });
              }}
              onFinish={() =>
                this.setState(
                  { ...this.state, progress: true },
                  this.submitForm
                )
              }
            >
              <button
                type="submit"
                id="image-submit-form"
                style={{ display: "none" }}
              />
              <Row gutter={[5, 5]}>
                <Col span={24}>
                  <Form.Item
                    name="order"
                    label="Order"
                    rules={[
                      {
                        required: true,
                        message: "Enter Image Order!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Image Order"
                      className="w-100"
                      min="0"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="image_name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Enter Image Order!",
                      },
                    ]}
                  >
                    <Input placeholder="Image Name" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <br />
            <div className="text-center">
              <small>
                Last updated by <b>{this.state.data.updated_by}</b> at{" "}
                <b>{this.state.data.updated_at}</b>
              </small>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  access: `Bearer ${state.auth.tokens.access}`,
});

export default connect(mapStateToProps, {})(ImageModal);
