import { combineReducers } from "redux";
import {
  SET_AUTH,
  SET_EXPIRY,
  SET_USER,
  SET_ACTIVE_PORTFOLIO_TAB,
  SET_TRAFFIC_DATA,
  SET_TRAFFIC_DATE_YEAR,
  SET_TRAFFIC_DATE_MONTH,
} from "./actions/types";

// AUTH REDUCER

function authReducer(state = { access: null, refresh: null }, action) {
  switch (action.type) {
    case SET_AUTH:
      return action.auth;
    default:
      return state;
  }
}

function expiryReducer(state = null, action) {
  switch (action.type) {
    case SET_EXPIRY:
      return action.expiry;
    default:
      return state;
  }
}

const authentication = combineReducers({
  tokens: authReducer,
  expiry: expiryReducer,
});

// USER REDUCER

export const userState = {
  username: "---",
};

function userReducer(state = userState, action) {
  switch (action.type) {
    case SET_USER:
      return action.user;
    default:
      return state;
  }
}

// PORTFOLIO REDUCER

function portfolioTabReducer(state = "professional", action) {
  switch (action.type) {
    case SET_ACTIVE_PORTFOLIO_TAB:
      return action.tab;
    default:
      return state;
  }
}

const portfolioReducer = combineReducers({
  tab: portfolioTabReducer,
});

// TRAFFIC REDUCER

function trafficDataReducer(
  state = {
    filter_year: "",
    filter_month: "",
    data: [],
    total: 0,
  },
  action
) {
  switch (action.type) {
    case SET_TRAFFIC_DATA:
      return action.data;
    default:
      return state;
  }
}

function trafficMonthReducer(state = new Date().getMonth() + 1, action) {
  switch (action.type) {
    case SET_TRAFFIC_DATE_MONTH:
      return action.month;
    default:
      return state;
  }
}

function trafficYearReducer(state = new Date().getFullYear(), action) {
  switch (action.type) {
    case SET_TRAFFIC_DATE_YEAR:
      return action.year;
    default:
      return state;
  }
}

const trafficSearchReducer = combineReducers({
  month: trafficMonthReducer,
  year: trafficYearReducer,
});

const trafficReducer = combineReducers({
  search: trafficSearchReducer,
  data: trafficDataReducer,
});

// ROOT REDUCER

export default combineReducers({
  auth: authentication,
  user: userReducer,
  portfolio: portfolioReducer,
  traffic: trafficReducer,
});
