import React from "react";
import { Menu, Divider } from "antd";
import {
  HomeOutlined,
  ProfileOutlined,
  LaptopOutlined,
  IdcardOutlined,
  UserOutlined,
  PoweroffOutlined,
  MailOutlined,
  BookOutlined,
  SwapOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

import head_pic from "../assets/img/undraw_data_processing.svg";

export default function CustomNav(props) {
  const location = useLocation();
  let activeLocation =
    location.pathname.split("/")[1] === ""
      ? "home"
      : location.pathname.split("/")[1];

  const [active, setActive] = React.useState(activeLocation);

  const [breakpoint, setBreakpoint] = React.useState(
    window.matchMedia("(max-width: 576px)").matches
  );

  window.matchMedia("(max-width: 576px)").onchange = (e) =>
    setBreakpoint(e.matches);

  if (active !== activeLocation) {
    setActive(activeLocation);
  }

  return (
    <React.Fragment>
      <div
        className="d-flex justify-content-between"
        style={{ flexDirection: "column", height: "100%" }}
      >
        <div>
          <div className="p-3 d-flex align-items-center justify-content-center">
            <img src={head_pic} width="100%" alt="logo" />
          </div>
          <Menu
            onClick={() => {
              if (breakpoint) props.toggle();
            }}
            selectedKeys={[active]}
            mode="inline"
            theme="light"
          >
            <Menu.Item key="home" icon={<HomeOutlined />}>
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item key="basic" icon={<ProfileOutlined />}>
              <Link to="/basic/">Basic</Link>
            </Menu.Item>
            <Menu.Item key="resume" icon={<CodeOutlined />}>
              <Link to="/resume/">Resume</Link>
            </Menu.Item>
            <Menu.Item key="portfolio" icon={<LaptopOutlined />}>
              <Link to="/portfolio/">Portfolio</Link>
            </Menu.Item>
            <Menu.Item key="personal" icon={<IdcardOutlined />}>
              <Link to="/personal/">Personal</Link>
            </Menu.Item>
            <Menu.Item key="messages" icon={<MailOutlined />}>
              <Link to="/messages/">Messages</Link>
            </Menu.Item>
            <Menu.Item key="quotes" icon={<BookOutlined />}>
              <Link to="/quotes/">Quotes</Link>
            </Menu.Item>
            <Menu.Item key="traffic" icon={<SwapOutlined />}>
              <Link to="/traffic/">Traffic</Link>
            </Menu.Item>
            <Divider key="divider" {...Menu.MenuItemProps} />
            <Menu.Item key="profile" icon={<UserOutlined />}>
              <Link to="/profile/">{props.values.user}</Link>
            </Menu.Item>
          </Menu>
        </div>
        <div>
          <Menu mode="inline" theme="light">
            <Menu.Item
              key="logout"
              icon={<PoweroffOutlined />}
              danger
              onClick={props.logOut}
            >
              Log Out
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </React.Fragment>
  );
}
