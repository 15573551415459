import React, { Component } from "react";
import { Layout, Modal } from "antd";
import { HashRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";

import { logOut, setAuth, setExpiry, setUser } from "./actions";
import { EXPIRY, API_ROOT } from "./common";
import { saveToLocalStorage, loadFromLocalStorage } from "./localStorage";
import { userState } from "./reducers";

import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./assets/css/overwrite-ant.css";
import "./assets/css/basic.css";
import "./assets/css/main.css";

import Login from "./pages/Login/Login";

import CustomNav from "./components/CustomNav";

import Profile from "./pages/Profile/Profile";
import Home from "./pages/Home/Home";
import Basic from "./pages/Basic/Basic";
import Resume from "./pages/Resume/Resume";
import Portfolio from "./pages/Portfolio/Portfolio";
import PortfolioDetail from "./pages/Portfolio/PortfolioDetail";
import Personal from "./pages/Personal/Personal";
import Messages from "./pages/Messages/Messages";
import Quotes from "./pages/Quotes/Quotes";
import Traffic from "./pages/Traffic/Traffic";
import TrafficDetail from "./pages/Traffic/TrafficDetail";
import TrafficHourly from "./pages/Traffic/TrafficHourly";

import Lost from "./pages/Lost";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      auth: this.props.auth,
      authenticated: false,
      navCollapsed: false,
      // navWidth: window.matchMedia("(max-width: 576px)").matches
      //   ? "100%"
      //   : "200px",
    };
  }

  componentDidMount() {
    let state = loadFromLocalStorage();

    if (state === undefined) {
      let auth =
        this.props.auth &&
        Object.keys(this.props.auth).length === 0 &&
        this.props.auth.constructor === Object
          ? { tokens: null, expiry: null }
          : this.props.auth;
      state = { auth, user: this.props.user || userState };
    }

    if (state.auth.tokens && state.auth.expiry && state.user) {
      if (state.auth.expiry < Date.now()) {
        this.refreshToken();
      } else {
        this.props.setAuth(state.auth.tokens);
        this.props.setExpiry(state.auth.expiry);
        this.props.setUser(state.user);

        saveToLocalStorage(state);
      }

      this.setState({ ...this.state, authenticated: true }, () => {
        window.interval = setInterval(this.refreshToken, 1 * 60000);
      });
    } else {
      this.setState({ ...this.state, authenticated: false });
      clearInterval(window.interval);
      localStorage.removeItem("state");
    }

    // window.matchMedia("(max-width: 576px)").onchange = (e) =>
    //   this.setState({ ...this.state, navWidth: e.matches ? "100%" : "200px" });
  }

  componentDidUpdate() {
    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }

    if (this.state.auth !== this.props.auth) {
      this.setState({ ...this.state, auth: this.props.auth });
    }
  }

  refreshToken = () => {
    let state = {
      auth: this.state.auth,
      user: this.props.user,
    };

    $.ajax({
      method: "POST",
      url: API_ROOT + "/auth/token/refresh/",
      data: {
        refresh: this.props.auth.tokens.refresh,
      },
      success: (resp) => {
        let expiry = EXPIRY();

        state.auth.tokens.access = resp.access;
        state.auth.expiry = expiry;

        this.props.setAuth(state.auth.tokens);
        this.props.setExpiry(expiry);

        saveToLocalStorage(state);
      },
      error: (err) => {
        console.log(err);
        clearInterval(window.interval);

        Modal.warning({
          title: "Couldn't refresh session!",
          content: "Log In Again.",
          afterClose: () => {
            this.logUserOut();
            localStorage.removeItem("state");
          },
        });
      },
    });
  };

  authenticateUser = (auth, user) => {
    let expiry = EXPIRY();
    let state = {
      auth: { tokens: auth, expiry },
      user,
    };

    this.props.setAuth(auth);
    this.props.setUser(user);
    this.props.setExpiry(expiry);

    saveToLocalStorage(state);

    this.setState({ ...this.state, authenticated: true }, () => {
      window.interval = setInterval(this.refreshToken, 1 * 60000);
    });
  };

  logUserOut = () => {
    this.setState(
      {
        ...this.state,
        authenticated: false,
      },
      () => this.props.logOut()
    );
  };

  toggleNavCollapse = () =>
    this.setState({ ...this.state, navCollapsed: !this.state.navCollapsed });

  app = () => (
    <React.Fragment>
      <HashRouter>
        <Layout>
          <Layout.Sider
            style={{
              overflow: "auto",
              height: "100vh",
              position: "sticky",
              top: 0,
              left: 0,
            }}
            theme="light"
            trigger={null}
            // width={
            //   window.matchMedia("(max-width: 576px)").matches ? "100%" : "200px"
            // }
            // width={this.state.navWidth}
            breakpoint="sm"
            collapsedWidth="0"
            collapsed={this.state.navCollapsed}
            onCollapse={(collapse) =>
              this.setState({ ...this.state, navCollapsed: collapse })
            }
          >
            <CustomNav
              toggle={this.toggleNavCollapse}
              values={{
                user: this.props.user,
              }}
              logOut={this.logUserOut}
            />
          </Layout.Sider>
          <Layout>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Home
                    toggleNavCollapse={this.toggleNavCollapse}
                    navCollapsed={this.state.navCollapsed}
                    logUserOut={this.logUserOut}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/basic/"
                render={(props) => (
                  <Basic
                    toggleNavCollapse={this.toggleNavCollapse}
                    navCollapsed={this.state.navCollapsed}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/resume/"
                render={(props) => (
                  <Resume
                    toggleNavCollapse={this.toggleNavCollapse}
                    navCollapsed={this.state.navCollapsed}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/portfolio/:id/"
                render={(props) => (
                  <PortfolioDetail
                    toggleNavCollapse={this.toggleNavCollapse}
                    navCollapsed={this.state.navCollapsed}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/portfolio/"
                render={(props) => (
                  <Portfolio
                    toggleNavCollapse={this.toggleNavCollapse}
                    navCollapsed={this.state.navCollapsed}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/personal/"
                render={(props) => (
                  <Personal
                    toggleNavCollapse={this.toggleNavCollapse}
                    navCollapsed={this.state.navCollapsed}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/messages/"
                render={(props) => (
                  <Messages
                    toggleNavCollapse={this.toggleNavCollapse}
                    navCollapsed={this.state.navCollapsed}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/quotes/"
                render={(props) => (
                  <Quotes
                    toggleNavCollapse={this.toggleNavCollapse}
                    navCollapsed={this.state.navCollapsed}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/traffic/:ip/hourly/:date/"
                render={(props) => (
                  <TrafficHourly
                    toggleNavCollapse={this.toggleNavCollapse}
                    navCollapsed={this.state.navCollapsed}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/traffic/:ip/"
                render={(props) => (
                  <TrafficDetail
                    toggleNavCollapse={this.toggleNavCollapse}
                    navCollapsed={this.state.navCollapsed}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/traffic/"
                render={(props) => (
                  <Traffic
                    toggleNavCollapse={this.toggleNavCollapse}
                    navCollapsed={this.state.navCollapsed}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/profile/"
                render={(props) => (
                  <Profile
                    toggleNavCollapse={this.toggleNavCollapse}
                    navCollapsed={this.state.navCollapsed}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="*"
                render={(props) => (
                  <Lost
                    toggleNavCollapse={this.toggleNavCollapse}
                    navCollapsed={this.state.navCollapsed}
                    {...props}
                  />
                )}
              />
            </Switch>
          </Layout>
        </Layout>
      </HashRouter>
    </React.Fragment>
  );

  render() {
    return (
      <React.Fragment>
        {this.state.authenticated ? (
          this.app()
        ) : (
          <Login authenticateUser={this.authenticateUser} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  access: "Bearer " + state.auth.tokens.access,
  refresh: state.auth.tokens.refresh,
  user: state.user,
});

const mapDispatchToProps = {
  logOut,
  setAuth,
  setExpiry,
  setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
