import React, { Component } from "react";
import {
  Drawer,
  Form,
  Input,
  Space,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { connect } from "react-redux";
import $ from "jquery";

import { API_ROOT } from "../../../common";

class AddPortfolioDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: false,
      show: this.props.show,
      data: {
        title: "",
        description: "",
        section: this.props.section.id,
        git_link: "",
        download_link: "",
      },
    };

    this.form = React.createRef();
  }

  componentDidUpdate() {
    if (this.state.show !== this.props.show) {
      this.setState({ ...this.state, show: this.props.show });
    }

    if (this.state.data.section !== this.props.section.id) {
      let { data } = this.state;
      data.section = this.props.section.id;
      this.setState({ ...this.state, data });
    }
  }

  submitForm = () =>
    $.ajax({
      url: API_ROOT + "/rest/portfolio/",
      method: "POST",
      headers: { Authorization: this.props.access },
      data: this.state.data,
      success: (resp) => {
        if (resp.status) {
          notification.success({ message: resp.message });
          this.props.history.push(`/portfolio/${resp.results.id}/`);
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
        this.setState({ ...this.state, progress: false });
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
        this.setState({ ...this.state, progress: false });
      },
    });

  render() {
    return (
      <Drawer
        title={`Add New Portfolio under '${this.props.section.title}'`}
        visible={this.state.show}
        closable={!this.state.progress}
        keyboard={!this.state.progress}
        maskClosable={!this.state.progress}
        height={window.matchMedia("(max-width: 576px)").matches ? "80%" : "55%"}
        placement="bottom"
        onClose={this.props.close}
        afterVisibleChange={(visible) => {
          if (!visible) {
            this.props.clear();
            this.form.current.resetFields();
          }
        }}
        footer={
          <Space className="put-center">
            <Button
              type="primary"
              onClick={() => $("#add-portfolio-form-btn").trigger("click")}
              loading={this.state.progress}
              disabled={this.state.progress}
            >
              Submit
            </Button>
          </Space>
        }
      >
        <Form
          name="add-portfolio-form"
          layout="vertical"
          ref={this.form}
          fields={[
            {
              name: "title",
              value: this.state.data.title,
            },
            {
              name: "description",
              value: this.state.data.description,
            },
            {
              name: "git_link",
              value: this.state.data.git_link,
            },
            {
              name: "download_link",
              value: this.state.data.download_link,
            },
          ]}
          onValuesChange={(e) => {
            let { data } = this.state;

            let key = Object.keys(e)[0];

            data[key] = e[key];

            this.setState({ ...this.state, data });
          }}
          onFinish={() =>
            this.setState({ ...this.state, progress: true }, this.submitForm)
          }
        >
          <button
            type="submit"
            id="add-portfolio-form-btn"
            style={{ display: "none" }}
          />
          <Row gutter={[5, 5]}>
            <Col span={24}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Enter Portfolio Title!",
                  },
                ]}
              >
                <Input placeholder="Portfolio Title" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Enter Portfolio Description!",
                  },
                ]}
              >
                <Input.TextArea placeholder="Portfolio Description" rows="6" />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item name="git_link" label="Git">
                <Input placeholder="Git Link" />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item name="download_link" label="Download">
                <Input placeholder="Download Link" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  access: `Bearer ${state.auth.tokens.access}`,
});

export default connect(mapStateToProps, {})(AddPortfolioDrawer);
