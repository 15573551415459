import React, { Component } from "react";
import { Fade, Container } from "react-bootstrap";
import { Card, Form, Input, Space, Button, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import $ from "jquery";

import { API_ROOT, HEADER } from "../../common";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: false,
      username: "",
      password: "",
    };
  }

  submitForm = () =>
    $.ajax({
      url: API_ROOT + "/auth/token/login/",
      method: "POST",
      headers: { app: HEADER },
      data: {
        username: this.state.username,
        password: this.state.password,
      },
      success: (resp) =>
        this.setState({ ...this.state, progress: false }, () =>
          this.props.authenticateUser(
            { access: resp.access, refresh: resp.refresh },
            this.state.username
          )
        ),

      error: (err) => {
        console.log(err);
        notification.error({ message: "Unable to authenticate" });
        this.setState({ ...this.state, progress: false });
      },
    });

  render() {
    return (
      <React.Fragment>
        <Fade appear={true} in={true}>
          <Container>
            <div className="put-center w-100" style={{ height: "100vh" }}>
              <Card
                className="login-card"
                style={{
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
              >
                <Form
                  name="login"
                  layout="vertical"
                  fields={[
                    {
                      name: "username",
                      value: this.state.username,
                    },
                    {
                      name: "password",
                      value: this.state.password,
                    },
                  ]}
                  onValuesChange={(e) => {
                    let { username, password } = this.state;

                    let key = Object.keys(e)[0];

                    if (key === "username") {
                      username = e[key];
                    } else {
                      password = e[key];
                    }

                    this.setState({ ...this.state, username, password });
                  }}
                  onFinish={() =>
                    this.setState(
                      { ...this.state, progress: true },
                      this.submitForm
                    )
                  }
                >
                  <Space direction="vertical">
                    <Form.Item
                      label="Username"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Enter Username!",
                        },
                      ]}
                    >
                      <Input placeholder="Username" prefix={<UserOutlined />} />
                    </Form.Item>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Enter Password!",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Password"
                        prefix={<LockOutlined />}
                      />
                    </Form.Item>
                    <div className="text-center">
                      <Button
                        htmlType="submit"
                        type="primary"
                        loading={this.state.progress}
                        disabled={this.state.progress}
                      >
                        Log In
                      </Button>
                    </div>
                  </Space>
                </Form>
              </Card>
            </div>
          </Container>
        </Fade>
      </React.Fragment>
    );
  }
}
