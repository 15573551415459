import React, { Component } from "react";
import {
  Drawer,
  Form,
  Upload,
  Input,
  Button,
  Space,
  Switch,
  Popconfirm,
  Row,
  Col,
  DatePicker,
  notification,
  message,
} from "antd";
import {
  LoadingOutlined,
  UploadOutlined,
  InboxOutlined,
  ShareAltOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import $ from "jquery";
import moment from "moment";
import copy from "copy-to-clipboard";

import { API_ROOT } from "../../../common";

class QuoteDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      show: this.props.show,
      data: this.props.data,
      image: {
        progress: false,
        data: "",
      },
      upload: {
        disabled: false,
      },
      progress: false,
    };

    this.form = React.createRef();
  }

  componentDidUpdate() {
    if (this.state.data !== this.props.data) {
      this.setState({ ...this.state, data: this.props.data }, () => {
        if (this.state.data.image_exists) this.fetchImage();
      });
    }

    if (this.state.show !== this.props.show) {
      this.setState({ ...this.state, show: this.props.show });
    }

    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }
  }

  fetchImage = () =>
    $.ajax({
      url: API_ROOT + `/rest/quote-images/${this.state.data.id}/`,
      method: "GET",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        let { image, data } = this.state;
        image.progress = false;
        if (resp.status) {
          data.image_exists = true;
          image.data = resp.results.image;
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
        this.setState({ ...this.state, image, data });
      },
      error: (err) => {
        console.log(err);

        let { image } = this.state;
        image.progress = false;
        this.setState({ ...this.state, image });

        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  submitQuoteForm = () => {
    let url;
    let method;

    if (this.props.add) {
      url = API_ROOT + "/rest/quotes/";
      method = "POST";
    } else {
      url = API_ROOT + `/rest/quotes/${this.state.data.id}/`;
      method = "PUT";
    }

    $.ajax({
      url,
      method,
      headers: { Authorization: this.state.access },
      data: {
        quote: this.state.data.quote,
        author: this.state.data.author,
        date: this.state.data.date ? this.state.data.date._i : "",
        favorite: this.state.data.favorite,
        additional_notes: this.state.data.additional_notes,
      },
      success: (resp) => {
        this.setState({ ...this.state, progress: false });
        if (resp.status) {
          // this.setState({ ...this.state, data: resp.results });
          this.props.fetch();
          if (this.props.add) this.props.close();
          notification.success({ message: resp.message });
        } else {
          this.setState({ ...this.state, progress: false });
          notification.warning({ message: resp.error || resp.message });
        }
      },
      error: (err) => {
        console.log(err);
        this.setState({ ...this.state, progress: false });
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });
  };

  deleteQuote = () =>
    $.ajax({
      url: API_ROOT + `/rest/quotes/${this.state.data.id}/`,
      method: "DELETE",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        this.setState({ ...this.state, progress: false });
        if (resp.status) {
          notification.success({ message: resp.message });
          this.props.fetch();
          this.props.close();
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
      },
      error: (err) => {
        console.log(err);
        this.setState({ ...this.state, progress: false });
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  deleteQuoteImage = () =>
    $.ajax({
      url: API_ROOT + `/rest/quote-images/${this.state.data.id}/`,
      method: "DELETE",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        this.setState({ ...this.state, progress: false });
        if (resp.status) {
          notification.success({ message: resp.message });

          let { image, data } = this.state;
          image.data = "";
          data.image_exists = false;
          this.setState({ ...this.state, image, data });

          this.props.fetch();
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
      },
      error: (err) => {
        console.log(err);
        this.setState({ ...this.state, progress: false });
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  render() {
    return (
      <React.Fragment>
        <Drawer
          visible={this.state.show}
          title={
            this.props.add
              ? "New Quote"
              : `Quote from ${this.state.data.author}`
          }
          closable={!this.state.progress}
          keyboard={!this.state.progress}
          maskClosable={!this.state.progress}
          height={
            window.matchMedia("(max-width: 576px)").matches ? "100%" : "90%"
          }
          placement="bottom"
          onClose={this.props.close}
          afterVisibleChange={(visible) => {
            if (!visible) {
              let { image } = this.state;
              image.data = "";
              this.setState({ ...this.state, image });
              this.props.clear();
            }
          }}
          footer={
            <Space wrap className="put-center">
              <Button
                type="default"
                onClick={this.props.close}
                disabled={this.state.progress}
              >
                Close
              </Button>
              <Button
                type="primary"
                onClick={() => $("#quote-form-btn").trigger("click")}
                loading={this.state.progress}
                disabled={this.state.progress}
              >
                Submit
              </Button>
              {!this.props.add ? (
                <React.Fragment>
                  <Space>
                    <Popconfirm
                      title="Delete this quote?"
                      placement="top"
                      onConfirm={() =>
                        this.setState(
                          { ...this.state, progress: true },
                          this.deleteQuote
                        )
                      }
                      okText="Yes"
                      cancelText="No"
                      okType="danger"
                      disabled={this.state.progress}
                    >
                      <Button
                        type="primary"
                        danger
                        disabled={this.state.progress}
                      >
                        Remove
                      </Button>
                    </Popconfirm>
                    <Button
                      type="dashed"
                      onClick={() => {
                        copy(
                          `${API_ROOT}/api/quotes/web/?id=${this.state.data.id}`
                        );
                        message.success("Share link copied.");
                      }}
                      disabled={this.state.progress}
                    >
                      <ShareAltOutlined />
                    </Button>
                    <Button
                      type="dashed"
                      href={`${API_ROOT}/api/quotes/web/?id=${this.state.data.id}`}
                      target="_blank"
                    >
                      <ExportOutlined />
                    </Button>
                  </Space>
                </React.Fragment>
              ) : null}
            </Space>
          }
        >
          <Row gutter={[5, 5]}>
            <Col sm={this.props.add ? 24 : 12}>
              <Form
                // className="w-100"
                name="quote-form"
                ref={this.form}
                layout="vertical"
                fields={[
                  {
                    name: "quote",
                    value: this.state.data.quote,
                  },
                  {
                    name: "author",
                    value: this.state.data.author,
                  },
                  {
                    name: "additional_notes",
                    value: this.state.data.additional_notes,
                  },
                  {
                    name: "date",
                    value: this.state.data.date,
                  },
                ]}
                onValuesChange={(e) => {
                  let { data } = this.state;

                  let key = Object.keys(e)[0];

                  if (key === "date" && e[key]) {
                    let hour = e[key]._d.getHours();

                    data[key] = moment(
                      `${e[key]._d.getFullYear()}-${String(
                        e[key]._d.getMonth() + 1
                      ).padStart(2, "0")}-${String(
                        e[key]._d.getDate()
                      ).padStart(2, "0")} ${String(
                        hour > 12 ? hour - 12 : hour
                      ).padStart(2, "0")}:${String(
                        e[key]._d.getMinutes()
                      ).padStart(2, "0")}:${String(
                        e[key]._d.getSeconds()
                      ).padStart(2, "0")} ${hour > 12 ? "pm" : "am"}`,
                      "YYYY-MM-DD hh:mm:ss a"
                    );
                  } else {
                    data[key] = e[key];
                  }

                  this.setState({ ...this.state, data });
                }}
                onFinish={() =>
                  this.setState(
                    { ...this.state, progress: true },
                    this.submitQuoteForm
                  )
                }
              >
                <button
                  type="submit"
                  style={{ display: "none" }}
                  id="quote-form-btn"
                />
                <Space direction="vertical">
                  <Form.Item
                    name="quote"
                    label="Quote"
                    rules={[
                      {
                        required: true,
                        message: "Enter Quote!",
                      },
                    ]}
                  >
                    <Input.TextArea placeholder="Quote" rows="5" />
                  </Form.Item>
                  <Form.Item
                    name="author"
                    label="Author"
                    rules={[
                      {
                        required: true,
                        message: "Enter Author Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Author" />
                  </Form.Item>
                  <Form.Item name="additional_notes" label="Additional Notes">
                    <Input.TextArea placeholder="Additional Notes" rows="5" />
                  </Form.Item>
                  <Row gutter={[5, 5]}>
                    <Col sm={12}>
                      <Form.Item label="Favorite">
                        <Switch
                          checked={this.state.data.favorite}
                          onChange={() => {
                            let { data } = this.state;
                            data.favorite = !data.favorite;
                            this.setState({ ...this.state, data });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <Form.Item name="date" label="Date">
                        <DatePicker
                          className="w-100"
                          placeholder="Date"
                          format="YYYY-MM-DD hh:mm:ss a"
                          showTime
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Space>
              </Form>
            </Col>
            {!this.props.add ? (
              <Col sm={12}>
                {window.matchMedia("(max-width: 576px)").matches ? (
                  <hr />
                ) : null}
                {this.state.data.image_exists ? (
                  <div
                    className={
                      window.matchMedia("(max-width: 576px)").matches
                        ? "w-100"
                        : "w-100 h-100"
                    }
                  >
                    {this.state.image.progress ? (
                      <div className="h-100 put-center">
                        <h5 className="put-center">
                          <LoadingOutlined className="mr-2" /> Fetching
                        </h5>
                      </div>
                    ) : this.state.image.data ? (
                      <div
                        className={
                          window.matchMedia("(max-width: 576px)").matches
                            ? ""
                            : "h-100"
                        }
                      >
                        <Space
                          className={
                            window.matchMedia("(max-width: 576px)").matches
                              ? "put-center"
                              : "put-center h-100"
                          }
                          direction="vertical"
                        >
                          <div className="w-100 put-center">
                            <img
                              className="mr-auto ml-auto"
                              src={this.state.image.data}
                              style={{ width: "70%" }}
                              alt="quote"
                            />
                          </div>
                          <Space direction="horizontal" wrap>
                            <Upload
                              name="image"
                              method="PUT"
                              className="ml-auto mr-auto"
                              action={
                                API_ROOT +
                                `/rest/quote-images/${this.state.data.id}/`
                              }
                              headers={{ Authorization: this.props.access }}
                              accept="image/png, image/jpeg"
                              onChange={(info) => {
                                const { status } = info.file;
                                let { progress, upload } = this.state;
                                upload.disabled = true;
                                progress = true;

                                if (status === "done") {
                                  notification.success({
                                    message: "Image Uploaded.",
                                  });

                                  upload.disabled = false;
                                  progress = false;

                                  this.fetchImage();
                                } else if (status === "error") {
                                  notification.error({
                                    message: "Image Upload Failed.",
                                  });

                                  upload.disabled = false;
                                  progress = false;
                                }

                                this.setState({
                                  ...this.state,
                                  progress,
                                  upload,
                                });
                              }}
                              showUploadList={false}
                              disabled={this.state.upload.disabled}
                            >
                              <Button
                                type="primary"
                                ghost
                                loading={this.state.upload.disabled}
                                disabled={this.state.upload.disabled}
                              >
                                {!this.state.upload.disabled ? (
                                  <UploadOutlined />
                                ) : null}{" "}
                                Upload New Image
                              </Button>
                            </Upload>
                            <Popconfirm
                              title="Remove this quote image?"
                              placement="top"
                              onConfirm={() =>
                                this.setState(
                                  { ...this.state, progress: true },
                                  this.deleteQuoteImage
                                )
                              }
                              okText="Yes"
                              cancelText="No"
                              okType="danger"
                              disabled={this.state.progress}
                            >
                              <Button type="primary" ghost danger>
                                Delete
                              </Button>
                            </Popconfirm>
                          </Space>
                        </Space>
                      </div>
                    ) : (
                      <div className="h-100 put-center">
                        <h5 className="put-center">
                          <LoadingOutlined className="mr-2" /> Processing
                        </h5>
                      </div>
                    )}
                  </div>
                ) : !this.props.add ? (
                  <div className="h-100">
                    <Upload.Dragger
                      name="image"
                      method="PUT"
                      className="ml-auto mr-auto"
                      action={
                        API_ROOT + `/rest/quote-images/${this.state.data.id}/`
                      }
                      headers={{ Authorization: this.props.access }}
                      accept="image/png, image/jpeg"
                      onChange={(info) => {
                        const { status } = info.file;
                        let { progress, upload } = this.state;
                        upload.disabled = true;
                        progress = true;

                        if (status === "done") {
                          notification.success({
                            message: "Image Uploaded",
                          });

                          upload.disabled = false;
                          progress = false;

                          this.fetchImage();
                        } else if (status === "error") {
                          notification.error({
                            message: "Image Upload Failed.",
                          });

                          upload.disabled = false;
                          progress = false;
                        }

                        this.setState({ ...this.state, progress, upload });
                      }}
                      onDrop={(e) => {
                        let { upload } = this.state;
                        if (e.dataTransfer.files.length > 0) {
                          upload.disabled = true;
                        } else {
                          upload.disabled = false;
                        }
                        this.setState({ ...this.state, upload });
                      }}
                      disabled={this.state.upload.disabled}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Upload New Image</p>
                    </Upload.Dragger>
                  </div>
                ) : null}
              </Col>
            ) : null}
          </Row>
        </Drawer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
});

export default connect(mapStateToProps, {})(QuoteDrawer);
