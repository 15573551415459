import React, { Component } from "react";
import { Fade, Container } from "react-bootstrap";
import {
  Layout,
  PageHeader,
  Button,
  Breadcrumb,
  Form,
  Select,
  Input,
  Table,
  notification,
  message,
} from "antd";
import {
  MenuOutlined,
  ReloadOutlined,
  BellOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import $ from "jquery";
import {
  setTrafficDateMonth,
  setTrafficDateYear,
  setTrafficData,
} from "../../actions";

import { API_ROOT } from "../../common";

import "../../assets/css/traffic.css";

const yearRange = () => {
  let start = 2021;
  let thisYear = new Date().getFullYear();
  let range = [];

  while (start <= thisYear) {
    range.push(start);
    start += 1;
  }

  return range;
};

const fetchIPLocation = (ip) =>
  fetch(`https://ipapi.co/${ip}/json/`)
    .then((resp) => resp.json())
    .then((data) => [data.country_name, data.city, data.region])
    .catch((err) => {
      console.log(err);
      return "---";
    });

// const dummyData = {
//   filter_year: "",
//   filter_month: "",
//   data: [],
//   total: 0,
// };

class Traffic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      progress: false,
      fetched: this.props.data.data.length !== 0,
      data: this.props.data,
      search: this.props.search,
      yearRange: yearRange(),
      monthRange: [
        [1, "January"],
        [2, "February"],
        [3, "March"],
        [4, "April"],
        [5, "May"],
        [6, "June"],
        [7, "July"],
        [8, "August"],
        [9, "September"],
        [10, "October"],
        [11, "November"],
        [12, "December"],
      ],
      selectedIP: [],
    };
  }

  componentDidMount() {
    this.fetchTraffic();
  }

  componentDidUpdate() {
    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }

    if (this.state.data !== this.props.data) {
      this.setState({ ...this.state, data: this.props.data });
    }
  }

  fetchTraffic = () => {
    // if (this.state.data.data.length === 0)
    this.setState({ ...this.state, progress: true });

    $.ajax({
      url: API_ROOT + "/rest/call-counts/",
      method: "GET",
      headers: { Authorization: this.state.access },
      data: {
        year: this.state.search.year,
        month: this.state.search.month,
      },
      success: async (resp) => {
        let { data } = this.state;

        if (resp.status) {
          for (let ip of resp.results.data) {
            if (!ip.location) {
              let location = await fetchIPLocation(ip.ip);
              resp.results.data[resp.results.data.indexOf(ip)].location =
                location || null;
            } else {
              resp.results.data[resp.results.data.indexOf(ip)].location = [
                ip.location,
                ip.meta.city,
                ip.meta.region,
              ];
            }
          }
          data = resp.results;
        } else {
          notification.warning({ message: resp.error || resp.message });
        }

        this.props.setTrafficData(data);
        this.setState({ ...this.state, fetched: true, progress: false });
      },
      error: (err) => {
        console.log(err);
        this.setState({ ...this.state, fetched: true, progress: false });
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });
  };

  getTraffic = () => (
    <Table
      scroll={{ x: "100%" }}
      dataSource={this.state.data.data.map((call, index) => ({
        key: index + 1,
        ip: call,
        location: call.location,
        website: call.website,
        api: call.api,
        total: call.total,
        url: call.urlIP,
      }))}
      columns={[
        { title: "S.N", dataIndex: "key", key: "key" },
        {
          title: "IP Address",
          dataIndex: "ip",
          key: "ip",
          render: (call) => {
            if (call.read) {
              return (
                <span>
                  <span className="put-center">{call.ip}</span>
                </span>
              );
            } else {
              return (
                <span>
                  <span className="put-center">
                    {call.ip} <BellOutlined className="ml-1" />
                  </span>
                </span>
              );
            }
          },
        },
        {
          title: "Location",
          dataIndex: "location",
          key: "location",
          render: (location) => {
            if (location) {
              return (
                <div className="text-center">
                  <div>{location[0]}</div>
                  <small style={{ color: "##9c9c9c" }}>
                    {location[1]}, {location[2]}
                  </small>
                </div>
              );
            } else {
              return <span>---</span>;
            }
          },
        },
        { title: "Total Website Calls", dataIndex: "website", key: "website" },
        { title: "Total API Calls", dataIndex: "api", key: "api" },
        { title: "Total Calls", dataIndex: "total", key: "total" },
      ]}
      rowClassName={(traffic) =>
        !traffic.ip.read ? "bg-warning bg-ip-unread" : null
      }
      pagination={false}
      loading={!this.state.fetched || this.state.progress}
      rowSelection={{
        onChange: (selectedRowKeys, selectedRows) => {
          let { selectedIP } = this.state;
          selectedIP = selectedRows.map((ip) => ip.ip.ip);
          this.setState({ ...this.state, selectedIP });
        },
      }}
      onRow={(call) => {
        return {
          onClick: () => this.props.history.push(`/traffic/${call.url}/`),
        };
      }}
    />
  );

  markAsRead = () =>
    $.ajax({
      url: API_ROOT + "/rest/call-counts/",
      method: "POST",
      headers: { Authorization: this.state.access },
      data: {
        addresses: JSON.stringify(this.state.selectedIP),
      },
      success: (resp) => {
        if (resp.status) {
          notification.success({ message: resp.message });
          this.fetchTraffic();
        } else {
          notification.warning({ message: resp.error || resp.message });
          this.setState({ ...this.state, progress: false });
        }
      },
      error: (err) => {
        console.log(err);
        this.setState({ ...this.state, progress: false });
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  render() {
    return (
      <React.Fragment>
        <Layout.Header
          className={
            this.props.navCollapsed
              ? "ant-layout-header-full-width"
              : "ant-layout-header-calc-width"
          }
        >
          <PageHeader
            title="Traffic"
            backIcon={<MenuOutlined className="trigger" />}
            onBack={this.props.toggleNavCollapse}
            extra={
              this.props.navCollapsed ||
              !window.matchMedia("(max-width: 576px)").matches ? (
                <Button
                  type="text"
                  onClick={() =>
                    this.setState(
                      { ...this.state, progress: true },
                      this.fetchTraffic
                    )
                  }
                  disabled={!this.state.fetched || this.state.progress}
                  // loading={!this.state.fetched || this.state.progress}
                >
                  {this.state.progress || !this.state.fetched ? (
                    <LoadingOutlined />
                  ) : (
                    <ReloadOutlined />
                  )}
                </Button>
              ) : null
            }
          />
        </Layout.Header>
        <Layout.Content>
          <Fade appear={true} in={true}>
            <Container>
              <Breadcrumb>
                <BreadcrumbItem>Traffic</BreadcrumbItem>
              </Breadcrumb>
              <br />
              <Form
                name="search-form"
                fields={[
                  {
                    name: "year",
                    value: this.state.search.year,
                  },
                  {
                    name: "month",
                    value: this.state.search.month,
                  },
                ]}
                onValuesChange={(e) => {
                  let { search } = this.state;

                  let key = Object.keys(e)[0];

                  search[key] = e[key];

                  this.setState({ ...this.state, search }, () => {
                    if (key === "year") {
                      this.props.setTrafficDateYear(e[key]);
                    } else {
                      this.props.setTrafficDateMonth(e[key]);
                    }

                    this.fetchTraffic();
                  });
                }}
              >
                <Input.Group className="text-center" compact>
                  <Form.Item
                    name="year"
                    style={{ width: "50%" }}
                    rules={[
                      {
                        required: true,
                        message: "Choose Year!",
                      },
                    ]}
                  >
                    <Select className="w-100" placeholder="Year">
                      {this.state.yearRange.map((year, index) => (
                        <Select.Option key={index} value={year}>
                          {year}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="month"
                    style={{ width: "50%" }}
                    rules={[{ required: true, message: "Choose Month!" }]}
                  >
                    <Select className="w-100" placeholder="Year">
                      {this.state.monthRange.map((month, index) => (
                        <Select.Option key={index} value={month[0]}>
                          {month[1]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form>
              {this.state.selectedIP.length > 0 ? (
                <Button
                  type="text"
                  onClick={() => {
                    let { selectedIP } = this.state;
                    if (selectedIP.length === 0) {
                      message.warning("No Address Selected.");
                    } else {
                      this.setState(
                        { ...this.state, progress: true },
                        this.markAsRead
                      );
                    }
                  }}
                >
                  Mark as Read
                </Button>
              ) : null}
              <hr />
              <div className="text-center">
                <small>
                  Total:{" "}
                  {!this.state.fetched ? (
                    <LoadingOutlined />
                  ) : (
                    this.state.data.total
                  )}
                </small>
              </div>
              <br />
              {this.getTraffic()}
            </Container>
          </Fade>
        </Layout.Content>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
  search: state.traffic.search,
  data: state.traffic.data,
});

export default connect(mapStateToProps, {
  setTrafficDateMonth,
  setTrafficDateYear,
  setTrafficData,
})(Traffic);
