import React, { Component } from "react";
import { Fade, Container } from "react-bootstrap";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  List,
  Space,
  Card,
  Button,
  Descriptions,
  notification,
} from "antd";
import {
  BellOutlined,
  LoadingOutlined,
  MenuOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";

import { API_ROOT } from "../../common";

import APIChart from "./components/APIChart";
import WebsiteChart from "./components/WebsiteChart";

const dummyData = {
  all: [],
  website: "",
  api: "",
  daily: [],
};

class TrafficDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      progress: false,
      fetched: false,
      ip: this.props.match.params.ip,
      data: JSON.parse(JSON.stringify(dummyData)),
      info: {
        continent: "",
        country: "",
        city: "",
        region: "",
        isp: "",
      },
    };
  }

  componentDidMount() {
    this.fetchTrafficData();
  }

  componentDidUpdate() {
    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }
  }

  fetchTrafficData = () =>
    $.ajax({
      url: API_ROOT + `/rest/call-counts/${this.state.ip}/`,
      method: "GET",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        let { data } = this.state;
        if (resp.status) {
          data = resp.results;
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
        this.setState(
          { ...this.state, data, progress: false, fetched: true },
          () => {
            if (
              $.isEmptyObject(this.state.data.meta) ||
              this.state.data.meta.hasOwnProperty("error")
            ) {
              this.fetchIPInfo();
            } else {
              let { info } = this.state;
              info = this.state.data.meta;
              this.setState({ ...this.state, info });
            }
          }
        );
      },
      error: (err) => {
        console.log(err);
        this.setState({ ...this.state, progress: false, fetched: true });
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  fetchIPInfo = () =>
    fetch(`https://ipapi.co/${this.state.ip.split("-").join(".")}/json/`)
      .then((resp) => resp.json())
      .then((data) => {
        let { info } = this.state;
        // let lat = data.latitude;
        // let lon = data.longitude;

        // if (lat !== "Sign up to access" && lat && lon) {
        //   renderMap(lon, lat);
        // } else {
        //   console.log(lat, lon);
        //   $("#map").html("Can't access geographical location.");
        // }

        // let continent = `${data.continent_name} (${data.continent_code})`;
        info.continent = data.continent_code;
        // let flag = data.location.country_flag;
        info.country = `${data.country_name} (${data.country_code})`;
        info.city = data.city;
        // let language = `
        // <ul>
        //   ${data.location.languages.map((language) => {
        //     return `<li>${language.name} (${language.native}) | ${language.code}`;
        //   })}
        // </ul>`;

        // let region = data.region_name;
        info.region = data.region;
        // let zip = data.zip;

        info.isp = data.org;

        this.setState({ ...this.state, info });
      })
      .catch((err) => {
        console.log(err);

        notification.error({
          message: "Could not fetch location data.",
        });
      });

  render() {
    return (
      <React.Fragment>
        <Layout.Header
          className={
            this.props.navCollapsed
              ? "ant-layout-header-full-width"
              : "ant-layout-header-calc-width"
          }
        >
          <PageHeader
            title="Traffic Detail"
            backIcon={<MenuOutlined className="trigger" />}
            onBack={this.props.toggleNavCollapse}
            extra={
              this.props.navCollapsed ||
              !window.matchMedia("(max-width: 576px)").matches ? (
                <Button
                  type="text"
                  // loading={this.state.progress}
                  disabled={this.state.progress || !this.state.fetched}
                  onClick={() =>
                    this.setState(
                      { ...this.state, progress: true },
                      this.fetchTrafficData
                    )
                  }
                >
                  {!this.state.progress ? (
                    <ReloadOutlined />
                  ) : (
                    <LoadingOutlined />
                  )}
                </Button>
              ) : null
            }
          />
        </Layout.Header>
        <Layout.Content>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/traffic/">Traffic</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Details of {this.state.ip.split("-").join(".")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <Fade appear={true} in={true}>
            <Container>
              {this.state.fetched ? (
                <React.Fragment>
                  <Row className="text-center" gutter={[5, 5]}>
                    <Col md={12}>
                      <h6>Website Calls</h6>
                      <WebsiteChart
                        data={
                          this.state.data.website
                            ? JSON.parse(this.state.data.website)
                            : []
                        }
                      />
                    </Col>
                    <Col md={12}>
                      <h6>API Calls</h6>
                      <APIChart
                        data={
                          this.state.data.api
                            ? JSON.parse(this.state.data.api)
                            : []
                        }
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Card>
                    <h6 className="text-center">Daily Requests</h6>
                    <br />
                    <List
                      dataSource={this.state.data.daily}
                      bordered
                      renderItem={(call) => (
                        <List.Item>
                          <Link
                            className="w-100"
                            to={`/traffic/${this.state.ip}/hourly/${call.date}/`}
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <div className="put-between w-100">
                              <div className="put-center">
                                {call.date}{" "}
                                {!call.read ? (
                                  <BellOutlined className="ml-1" />
                                ) : null}
                              </div>
                              <div className="put-center">
                                <Space>Total: {call.total}</Space>
                              </div>
                            </div>
                          </Link>
                        </List.Item>
                      )}
                    />
                  </Card>
                  <hr />
                  <Card>
                    <Descriptions title="Info" bordered>
                      <Descriptions.Item label="Continent">
                        {this.state.info.continent ||
                          this.state.info.continent_code}
                      </Descriptions.Item>
                      <Descriptions.Item label="Country">
                        {this.state.info.country_name} {this.state.info.country}
                      </Descriptions.Item>
                      <Descriptions.Item label="City">
                        {this.state.info.city}
                      </Descriptions.Item>
                      <Descriptions.Item label="Region">
                        {this.state.info.region}
                      </Descriptions.Item>
                      <Descriptions.Item label="ISP">
                        {this.state.info.isp || this.state.info.org}
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </React.Fragment>
              ) : (
                <div className="p-5 text-center">
                  <h5 className="put-center">
                    <LoadingOutlined className="mr-1" /> Fetching
                  </h5>
                </div>
              )}
            </Container>
          </Fade>
        </Layout.Content>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
});

export default connect(mapStateToProps, {})(TrafficDetail);
