import React, { Component } from "react";
import { Fade, Container } from "react-bootstrap";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  Space,
  Popconfirm,
  Divider,
  Upload,
  Card,
  notification,
} from "antd";
import {
  MenuOutlined,
  LoadingOutlined,
  InboxOutlined,
  UploadOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import $ from "jquery";

import { API_ROOT } from "../../common";

import ImageModal from "./components/ImageModal";

const dummyData = {
  id: 0,
  S_N: 0,
  section: {
    id: 0,
    title: "",
  },
  title: "",
  description: "",
  git_link: null,
  download_link: null,
  created_at: "",
  created_by: "",
  updated_at: "",
  updated_by: "",
};

const dummyImageData = {
  id: 0,
  portfolio: {
    id: 0,
    title: "",
  },
  image_format: "",
  image_name: "",
  order: 0,
  created_at: "",
  created_by: "",
  updated_at: "",
  updated_by: "",
  image: "",
};

class PortfolioDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      progress: false,
      fetched: false,
      id: this.props.match.params.id,
      data: JSON.parse(JSON.stringify(dummyData)),
      images: {
        progress: false,
        fetched: false,
        data: [],
      },
      modal: {
        show: false,
        data: JSON.parse(JSON.stringify(dummyImageData)),
      },
    };
  }

  componentDidMount() {
    this.fetchPortfolioDetails();
    this.fetchImages();
  }

  componentDidUpdate() {
    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }
  }

  fetchPortfolioDetails = () =>
    $.ajax({
      url: API_ROOT + `/rest/portfolio/${this.state.id}/`,
      method: "GET",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        let { data } = this.state;

        if (resp.status) {
          data = resp.results;
        } else {
          notification.warning({ message: resp.error || resp.message });
        }

        this.setState({ ...this.state, data, progress: false, fetched: true });
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
        this.setState({ ...this.state, progress: false, fetched: true });
      },
    });

  fetchImages = () =>
    $.ajax({
      url: API_ROOT + "/rest/portfolio-image/",
      method: "GET",
      headers: { Authorization: this.state.access },
      data: { portfolio: this.state.id },
      success: (resp) => {
        let { images } = this.state;
        images.progress = false;
        images.fetched = true;

        if (resp.status) {
          images.data = resp.results;
        } else {
          notification.warning({ message: resp.error || resp.message });
        }

        this.setState({ ...this.state, images });
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });

        let { images } = this.state;
        images.progress = false;
        images.fetched = true;
        this.setState({ ...this.state, images });
      },
    });

  submitForm = () =>
    $.ajax({
      url: API_ROOT + `/rest/portfolio/${this.state.id}/`,
      method: "PUT",
      headers: { Authorization: this.state.access },
      data: {
        sn: this.state.data.S_N,
        title: this.state.data.title,
        description: this.state.data.description,
        section: this.state.data.section.id,
        git_link: this.state.data.git_link,
        download_link: this.state.data.download_link,
      },
      success: (resp) => {
        if (resp.status) {
          notification.success({ message: resp.message });
          this.fetchPortfolioDetails();
        } else {
          notification.warning({ message: resp.error || resp.message });
        }

        this.setState({ ...this.state, progress: false });
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
        this.setState({ ...this.state, progress: false });
      },
    });

  deletePortfolio = () =>
    $.ajax({
      url: API_ROOT + `/rest/portfolio/${this.state.id}/`,
      method: "DELETE",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        if (resp.status) {
          notification.success({ message: resp.message });
          this.props.history.push("/portfolio/");
        } else {
          notification.warning({ message: resp.error || resp.message });
        }

        this.setState({ ...this.state, progress: false });
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
        this.setState({ ...this.state, progress: false });
      },
    });

  openImageModal = (data) => {
    let { modal } = this.state;
    modal.show = true;
    modal.data = JSON.parse(JSON.stringify(data));
    this.setState({ ...this.state, modal });
  };

  closeImageModal = () => {
    let { modal } = this.state;
    modal.show = false;
    this.setState({ ...this.state, modal });
  };

  clearImageModal = () => {
    let { modal } = this.state;
    modal.show = false;
    modal.data = JSON.parse(JSON.stringify(dummyImageData));
    this.setState({ ...this.state, modal });
  };

  render() {
    return (
      <React.Fragment>
        <Layout.Header
          className={
            this.props.navCollapsed
              ? "ant-layout-header-full-width"
              : "ant-layout-header-calc-width"
          }
        >
          <PageHeader
            title="Portfolio Detail"
            backIcon={<MenuOutlined className="trigger" />}
            onBack={this.props.toggleNavCollapse}
            extra={
              this.props.navCollapsed ||
              !window.matchMedia("(max-width: 576px)").matches ? (
                <Button
                  type="text"
                  onClick={() =>
                    this.setState({ ...this.state, progress: true }, () => {
                      this.fetchPortfolioDetails();
                      this.fetchImages();
                      this.setState({ ...this.state, progress: false });
                    })
                  }
                  // loading={this.state.progress}
                  disabled={this.state.progress}
                >
                  {!this.state.progress ? (
                    <ReloadOutlined />
                  ) : (
                    <LoadingOutlined />
                  )}
                </Button>
              ) : null
            }
          />
        </Layout.Header>
        <Layout.Content>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/portfolio/">Portfolio</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {this.state.data.title || <LoadingOutlined />}
            </Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <Fade appear={true} in={true}>
            <Container>
              <Form
                name="portfolio-form"
                layout="vertical"
                fields={[
                  {
                    name: "S_N",
                    value: this.state.data.S_N,
                  },
                  {
                    name: "title",
                    value: this.state.data.title,
                  },
                  {
                    name: "description",
                    value: this.state.data.description,
                  },
                  {
                    name: "git_link",
                    value: this.state.data.git_link,
                  },
                  {
                    name: "download_link",
                    value: this.state.data.download_link,
                  },
                ]}
                onValuesChange={(e) => {
                  let { data } = this.state;

                  let key = Object.keys(e)[0];

                  data[key] = e[key];

                  this.setState({ ...this.state, data });
                }}
                onFinish={() =>
                  this.setState(
                    { ...this.state, progress: true },
                    this.submitForm
                  )
                }
              >
                <Row gutter={[5, 5]}>
                  <Col sm={4}>
                    <Form.Item
                      name="S_N"
                      label="S.N"
                      rules={[
                        {
                          required: true,
                          message: "Enter Portfolio S.N!",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="S.N"
                        className="w-100"
                        min="0"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={20}>
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: "Enter Portfolio Title!",
                        },
                      ]}
                    >
                      <Input placeholder="Title" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="description"
                      label="Description"
                      rules={[
                        {
                          required: true,
                          message: "Enter Portfolio Description!",
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="Description" rows="7" />
                    </Form.Item>
                  </Col>
                  <Col sm={12}>
                    <Form.Item name="git_link" label="Git">
                      <Input placeholder="Git Link" />
                    </Form.Item>
                  </Col>
                  <Col sm={12}>
                    <Form.Item name="download_link" label="Download">
                      <Input placeholder="Download Link" />
                    </Form.Item>
                  </Col>
                </Row>
                <Space wrap className="put-center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={!this.state.fetched || this.state.progress}
                    disabled={!this.state.fetched || this.state.progress}
                  >
                    Submit
                  </Button>
                  <Popconfirm
                    title="Delete this portfolio?"
                    okText="Yes"
                    cancelText="No"
                    okType="danger"
                    onConfirm={this.deletePortfolio}
                    disabled={!this.state.fetched || this.state.progress}
                  >
                    <Button
                      type="primary"
                      danger
                      ghost
                      disabled={!this.state.fetched && this.state.progress}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                </Space>
              </Form>
              <br />
              <Space wrap className="put-center">
                <small>{this.state.data.created_at}</small>|
                <small>{this.state.data.created_by}</small>|
                <small>{this.state.data.updated_at}</small>|
                <small>{this.state.data.updated_by}</small>|
              </Space>
              <Divider>Images</Divider>
              {this.state.images.fetched ? (
                this.state.images.data.length === 0 ? (
                  <Upload.Dragger
                    style={{ height: "100%", width: "100%" }}
                    name="images"
                    headers={{ Authorization: this.props.access }}
                    multiple={true}
                    action={API_ROOT + "/rest/portfolio-image/"}
                    data={{ portfolio: this.state.id }}
                    accept="image/png,image/jpeg"
                    onChange={(info) => {
                      let { images } = this.state;
                      images.progress = true;

                      const { status } = info.file;

                      // if (status !== "uploading") {
                      //   console.log(info.file, info.fileList);
                      // }

                      if (status === "done") {
                        images.progress = false;
                        notification.success({ message: "Images uploaded." });
                        this.fetchImages();
                      } else if (status === "error") {
                        notification.error({ message: "Upload failed." });
                      }

                      this.setState({ ...this.state, images });
                    }}
                    onDrop={() => {
                      let { images } = this.state;
                      images.progress = true;
                      this.setState({ ...this.state, images });
                    }}
                    disabled={this.state.images.progress}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Upload Portfolio Images</p>
                    <p className="ant-upload-hint">Drag or Drop or Click</p>
                  </Upload.Dragger>
                ) : (
                  <div>
                    <div className="text-center">
                      <Upload
                        name="images"
                        headers={{ Authorization: this.props.access }}
                        multiple={true}
                        action={API_ROOT + "/rest/portfolio-image/"}
                        data={{ portfolio: this.state.id }}
                        accept="image/png,image/jpeg"
                        onChange={(info) => {
                          let { images } = this.state;
                          images.progress = true;

                          const { status } = info.file;

                          // if (status !== "uploading") {
                          //   console.log(info.file, info.fileList);
                          // }

                          if (status === "done") {
                            images.progress = false;
                            notification.success({
                              message: "Images uploaded.",
                            });
                            this.fetchImages();
                          } else if (status === "error") {
                            notification.error({ message: "Upload failed." });
                          }

                          this.setState({ ...this.state, images });
                        }}
                        showUploadList={false}
                        disabled={this.state.images.progress}
                      >
                        <Button
                          type="primary"
                          loading={this.state.images.progress}
                          disabled={this.state.images.progress}
                        >
                          {!this.state.images.progress ? (
                            <UploadOutlined />
                          ) : null}{" "}
                          Upload
                        </Button>
                      </Upload>
                    </div>
                    <br />
                    <Row gutter={[5, 5]}>
                      {this.state.images.data.map((image, index) => (
                        <Col sm={8} key={index}>
                          <Card
                            className="w-100 clickable"
                            hoverable
                            cover={
                              <img
                                src={image.image}
                                alt={image.image_name}
                                style={{ height: "250px" }}
                              />
                            }
                            onClick={() => this.openImageModal(image)}
                          >
                            <Card.Meta
                              title={`${image.image_name}.${image.image_format}`}
                            />
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                )
              ) : (
                <div className="p-5 text-center">
                  <h5 className="put-center">
                    <LoadingOutlined className="mr-2" />
                    Fetching
                  </h5>
                </div>
              )}
            </Container>
          </Fade>
          <ImageModal
            show={this.state.modal.show}
            data={this.state.modal.data}
            fetch={this.fetchImages}
            close={this.closeImageModal}
            clear={this.clearImageModal}
          />
        </Layout.Content>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
});

export default connect(mapStateToProps, {})(PortfolioDetail);
