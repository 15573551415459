import { Drawer, Row, Col } from "antd";

export default function Message(props) {
  return (
    <Drawer
      visible={props.show}
      title={`Message from ${props.data.name}`}
      // height={window.matchMedia("(max-width: 576px)").matches ? "55%" : "45%"}
      height="80%"
      placement="bottom"
      onClose={props.close}
      afterVisibleChange={(visible) => (!visible ? props.clear() : null)}
    >
      <Row gutter={[5, 5]}>
        <Col sm={12}>
          Name
          <p className="ant-input mt-1">{props.data.name}</p>
        </Col>
        <Col sm={12}>
          Email
          <p className="ant-input mt-1">{props.data.email}</p>
        </Col>
        <Col span={24}>
          Message
          <textarea
            className="ant-input mt-1"
            value={props.data.message}
            rows="15"
            style={{
              pointerEvents: "none",
              overflowY: "auto",
              wordBreak: "break-all",
            }}
            readOnly
          />
        </Col>
      </Row>
      <br />
      <div className="text-center">
        <small>{props.data.created_at}</small>
      </div>
    </Drawer>
  );
}
