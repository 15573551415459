import React, { Component } from "react";
import { Fade, Container } from "react-bootstrap";
import {
  Layout,
  PageHeader,
  Button,
  Breadcrumb,
  Card,
  List,
  Row,
  Col,
  Tabs,
  notification,
} from "antd";
import {
  BellOutlined,
  LoadingOutlined,
  MenuOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import $ from "jquery";
import moment from "moment";

import { API_ROOT } from "../../common";

import APIChart from "./components/APIChart";
import WebsiteChart from "./components/WebsiteChart";

import HourlyDetailDrawer from "./components/HourlyDetailDrawer";

const dummyData = {
  ip: "",
  urlIP: "",
  hourly: {
    date: "",
    counts: {
      api: [],
      website: [],
    },
    data: {
      api: [],
      website: [],
    },
  },
};

const dummyDrawerData = {
  api: "",
  created_at: "",
  header: "",
  id: "",
  raw: "{}",
  read: false,
  request_with: "",
  updated_at: "",
  user_agent: "",
};

class TrafficHourly extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      progress: false,
      fetched: false,
      ip: this.props.match.params.ip,
      date: this.props.match.params.date,
      data: JSON.parse(JSON.stringify(dummyData)),
      nav: "website",
      drawer: {
        show: false,
        data: JSON.parse(JSON.stringify(dummyDrawerData)),
        time: "",
      },
    };
  }

  componentDidMount() {
    this.fetchHourlyData();
  }

  componentDidUpdate() {
    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }
  }

  fetchHourlyData = () =>
    $.ajax({
      url: API_ROOT + `/rest/call-counts/${this.state.ip}/`,
      method: "GET",
      headers: { Authorization: this.state.access },
      data: { date: this.state.date },
      success: (resp) => {
        let { data } = this.state;
        if (resp.status) {
          data = resp.results;
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
        this.setState({ ...this.state, data, progress: false, fetched: true });
      },
      error: (err) => {
        console.log(err);
        this.setState({ ...this.state, progress: false, fetched: true });
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  openDetailDrawer = (data, time) => {
    let { drawer } = this.state;

    drawer.show = true;
    drawer.data = JSON.parse(JSON.stringify(data));
    drawer.time = time;

    this.setState({ ...this.state, drawer });
  };

  closeDetailDrawer = () => {
    let { drawer } = this.state;

    drawer.show = false;

    this.setState({ ...this.state, drawer });
  };

  clearDetailDrawer = () => {
    let { drawer } = this.state;

    drawer.show = false;
    drawer.data = JSON.parse(JSON.stringify(dummyDrawerData));
    drawer.time = "";

    this.setState({ ...this.state, drawer });
  };

  getCalls = () => (
    <Tabs
      activeKey={this.state.nav}
      onChange={(key) => this.setState({ ...this.state, nav: key })}
    >
      <Tabs.TabPane tab="Website Calls" key="website">
        <Card>
          Total: {this.state.data.hourly.counts.website.length}
          <List
            dataSource={this.state.data.hourly.counts.website}
            bordered
            renderItem={(call) => {
              let time = moment(call.created_at);
              let hour = time._d.getHours();

              let callTime = `${String(hour > 12 ? hour - 12 : hour).padStart(
                2,
                "0"
              )}:${String(time._d.getMinutes()).padStart(2, "0")}:${String(
                time._d.getSeconds()
              ).padStart(2, "0")} ${hour > 12 ? "pm" : "am"}`;

              return (
                <List.Item
                  className="clickable"
                  onClick={() => this.openDetailDrawer(call, callTime)}
                >
                  <div className="put-between w-100">
                    <List.Item.Meta
                      title={callTime}
                      description={
                        <small style={{ wordBreak: "break-all" }}>
                          {call.user_agent}
                        </small>
                      }
                    />
                  </div>
                  {call.read ? null : <BellOutlined />}
                </List.Item>
              );
            }}
          />
        </Card>
      </Tabs.TabPane>
      <Tabs.TabPane tab="API Calls" key="api">
        <Card>
          Total: {this.state.data.hourly.counts.api.length}
          <List
            dataSource={this.state.data.hourly.counts.api}
            bordered
            renderItem={(call) => {
              let time = moment(call.created_at);
              let hour = time._d.getHours();
              let callTime = `${String(hour > 12 ? hour - 12 : hour).padStart(
                2,
                "0"
              )}:${String(time._d.getMinutes()).padStart(2, "0")}:${String(
                time._d.getSeconds()
              ).padStart(2, "0")} ${hour > 12 ? "pm" : "am"}`;

              return (
                <List.Item
                  className="clickable"
                  onClick={() => this.openDetailDrawer(call, callTime)}
                >
                  <div className="put-between w-100">
                    <List.Item.Meta
                      title={callTime}
                      description={
                        <span style={{ wordBreak: "break-all" }}>
                          {call.api}
                        </span>
                      }
                    />
                  </div>
                  {call.read ? null : <BellOutlined />}
                </List.Item>
              );
            }}
          />
        </Card>
      </Tabs.TabPane>
    </Tabs>
  );

  render() {
    return (
      <React.Fragment>
        <Layout.Header
          className={
            this.props.navCollapsed
              ? "ant-layout-header-full-width"
              : "ant-layout-header-calc-width"
          }
        >
          <PageHeader
            title="Traffic Hourly"
            backIcon={<MenuOutlined className="trigger" />}
            onBack={this.props.toggleNavCollapse}
            extra={
              this.props.navCollapsed ||
              !window.matchMedia("(max-width: 576px)").matches ? (
                <Button
                  type="text"
                  // loading={this.state.progress}
                  disabled={this.state.progress || !this.state.fetched}
                  onClick={() =>
                    this.setState(
                      { ...this.state, progress: true },
                      this.fetchHourlyData
                    )
                  }
                >
                  {!this.state.progress ? (
                    <ReloadOutlined />
                  ) : (
                    <LoadingOutlined />
                  )}
                </Button>
              ) : null
            }
          />
        </Layout.Header>
        <Layout.Content>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/traffic/">Traffic</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/traffic/${this.state.ip}/`}>
                Details of {this.state.ip.split("-").join(".")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Hourly Detail ({this.state.data.hourly.date || this.state.date})
            </Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <Fade appear={true} in={true}>
            <Container>
              {this.state.fetched ? (
                <React.Fragment>
                  <Row className="text-center" gutter={[5, 5]}>
                    <Col md={12}>
                      <h6>Hourly Website Calls</h6>
                      <WebsiteChart
                        data={
                          this.state.data.hourly.data.website
                            ? JSON.parse(
                                this.state.data.hourly.data.website
                              ).map((website) => ({
                                date: website.time,
                                count: website.count,
                              }))
                            : []
                        }
                      />
                    </Col>
                    <Col md={12}>
                      <h6>Hourly API Calls</h6>
                      <APIChart
                        data={
                          this.state.data.hourly.data.api
                            ? JSON.parse(this.state.data.hourly.data.api).map(
                                (website) => ({
                                  date: website.time,
                                  count: website.count,
                                })
                              )
                            : []
                        }
                      />
                    </Col>
                  </Row>
                  <hr />
                  {this.getCalls()}
                </React.Fragment>
              ) : (
                <div className="p-5 text-center">
                  <h5 className="put-center">
                    <LoadingOutlined className="mr-1" /> Fetching
                  </h5>
                </div>
              )}
            </Container>
          </Fade>
          <HourlyDetailDrawer
            show={this.state.drawer.show}
            data={this.state.drawer.data}
            ip={this.state.data.ip || this.state.ip}
            time={this.state.drawer.time}
            close={this.closeDetailDrawer}
            clear={this.clearDetailDrawer}
          />
        </Layout.Content>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
});

export default connect(mapStateToProps, {})(TrafficHourly);
