import React, { Component } from "react";
import { Fade, Container } from "react-bootstrap";
import {
  Layout,
  PageHeader,
  Form,
  Input,
  // DatePicker,
  Button,
  Card,
  List,
  Empty,
  notification,
} from "antd";
import {
  MenuOutlined,
  SearchOutlined,
  HeartFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import $ from "jquery";
import moment from "moment";

import { API_ROOT } from "../../common";

import QuoteDrawer from "./components/QuoteDrawer";

// const today = () => {
//   let date = new Date();
//   let hour = date.getHours();

//   return moment(
//     `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
//       2,
//       "0"
//     )}-${String(date.getDate()).padStart(2, "0")} ${String(
//       hour > 12 ? hour - 12 : hour
//     ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(
//       date.getSeconds()
//     ).padStart(2, "0")} ${hour > 12 ? "pm" : "am"}`,
//     "YYYY-MM-DD hh:mm:ss a"
//   )._i;
// };

const dummyData = {
  id: 0,
  short: "",
  quote: "",
  author: "",
  favorite: false,
  image_exists: false,
  additional_notes: "",
  date: "",
  created_at: "",
  created_by: "",
  updated_at: "",
  updated_by: "",
};

class Quotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      fetched: false,
      progress: false,
      search: {
        quote: "",
        author: "",
        date: "",
        favorite: false,
      },
      quotes: [],
      drawer: {
        show: false,
        add: false,
        data: JSON.parse(JSON.stringify(dummyData)),
      },
    };
  }

  componentDidMount() {
    this.fetchQuotes();
  }

  componentDidUpdate() {
    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }
  }

  fetchQuotes = () => {
    this.setState({ ...this.state, progress: true });

    $.ajax({
      url: API_ROOT + "/rest/quotes/",
      method: "GET",
      headers: { Authorization: this.state.access },
      data: {
        quote: this.state.search.quote,
        author: this.state.search.author,
        date: this.state.search.date ? this.state.search.date._i : "",
        favorite: this.state.search.favorite,
      },
      success: (resp) => {
        if (resp.status) {
          this.setState({
            ...this.state,
            fetched: true,
            progress: false,
            quotes: resp.results,
          });
        } else {
          notification.warning({ message: resp.error || resp.message });
          this.setState({ ...this.state, fetched: true, progress: false });
        }
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error ||
              err.responseJSON.message ||
              "Something went wrong."
            : "Something went wrong.",
        });
        this.setState({ ...this.state, fetched: true, progress: false });
      },
    });
  };

  openQuoteDrawer = (data, add = false) => {
    let { drawer } = this.state;

    let drawerData = JSON.parse(JSON.stringify(data));
    if (!add)
      drawerData.date = moment(drawerData.date, "DD MMMM,YYYY hh:mm:ss a");

    drawer.show = true;
    drawer.add = add;
    drawer.data = drawerData;
    this.setState({ ...this.state, drawer });
  };

  closeQuoteDrawer = () => {
    let { drawer } = this.state;
    drawer.show = false;
    this.setState({ ...this.state, drawer });
  };

  clearQuoteDrawer = () => {
    let { drawer } = this.state;
    drawer.show = false;
    drawer.add = false;
    drawer.data = JSON.parse(JSON.stringify(dummyData));
    this.setState({ ...this.state, drawer });
  };

  render() {
    return (
      <React.Fragment>
        <Layout.Header
          className={
            this.props.navCollapsed
              ? "ant-layout-header-full-width"
              : "ant-layout-header-calc-width"
          }
        >
          <PageHeader
            title="Quotes"
            backIcon={<MenuOutlined className="trigger" />}
            onBack={this.props.toggleNavCollapse}
          />
        </Layout.Header>
        <Layout.Content>
          <Fade appear={true} in={true}>
            <Container>
              <Form
                name="search-quote-form"
                fields={[
                  {
                    name: "quote",
                    value: this.state.search.quote,
                  },
                  {
                    name: "author",
                    value: this.state.search.author,
                  },
                  {
                    name: "date",
                    value: this.state.search.date,
                  },
                ]}
                onValuesChange={(e) => {
                  let { search } = this.state;

                  let key = Object.keys(e)[0];

                  if (key === "date" && e[key]) {
                    search[key] = moment(
                      `${e[key]._d.getFullYear()}-${String(
                        e[key]._d.getMonth() + 1
                      ).padStart(2, "0")}-${String(
                        e[key]._d.getDate()
                      ).padStart(2, "0")}`,
                      "YYYY-MM-DD"
                    );
                  } else {
                    search[key] = e[key];
                  }

                  this.setState({ ...this.state, search });
                }}
                onFinish={this.fetchQuotes}
              >
                <Input.Group className="text-center" compact>
                  <Form.Item style={{ width: "45%" }} name="quote">
                    <Input placeholder="Quote" allowClear />
                  </Form.Item>
                  <Form.Item style={{ width: "45%" }} name="author">
                    <Input placeholder="Author" allowClear />
                  </Form.Item>
                  {/* <Form.Item style={{ width: "28%" }} name="Date">
                    <DatePicker
                      placeholder="Date"
                      className="w-100"
                      format="YYYY-MM-DD"
                      allowClear
                    />
                  </Form.Item> */}
                  <Button
                    style={{ width: "5%" }}
                    type="primary"
                    htmlType="submit"
                    disabled={this.state.progress || !this.state.fetched}
                  >
                    {!this.state.progress ? (
                      <SearchOutlined />
                    ) : (
                      <LoadingOutlined />
                    )}
                  </Button>
                </Input.Group>
              </Form>
              <br />
              <div className="put-between">
                <Button
                  type="primary"
                  onClick={() => this.openQuoteDrawer(dummyData, true)}
                  disabled={!this.state.fetched || this.state.progress}
                >
                  Add
                </Button>
                <div>
                  <small>Total: {this.state.quotes.length}</small>
                </div>
                <Button
                  type="default"
                  onClick={() => {
                    let { search } = this.state;
                    search.favorite = !search.favorite;
                    this.setState(
                      { ...this.state, search, progress: true },
                      this.fetchQuotes
                    );
                  }}
                  disabled={this.state.progress || !this.state.fetched}
                  loading={this.state.progress}
                >
                  Show {this.state.search.favorite ? "All" : "Favorites"}
                </Button>
              </div>
              <hr />
              <Card>
                {this.state.fetched ? (
                  this.state.quotes.length === 0 ? (
                    <Empty description="No Quotes" />
                  ) : (
                    <List
                      bordered
                      dataSource={this.state.quotes}
                      renderItem={(quote) => (
                        <List.Item
                          className="clickable"
                          onClick={() => this.openQuoteDrawer(quote)}
                        >
                          <div className="put-between w-100">
                            <List.Item.Meta
                              title={quote.short}
                              description={
                                <div className="put-between">
                                  <div className="put-center">
                                    {quote.author}
                                    {quote.favorite ? (
                                      <HeartFilled
                                        className="ml-1"
                                        style={{ color: "#cc0000" }}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              }
                            />
                            <small>{quote.date}</small>
                          </div>
                        </List.Item>
                      )}
                    />
                  )
                ) : (
                  <div className="p-5 text-center">
                    <h5 className="put-center">
                      <LoadingOutlined className="mr-2" />
                      Fetching
                    </h5>
                  </div>
                )}
              </Card>
            </Container>
          </Fade>
          <QuoteDrawer
            show={this.state.drawer.show}
            add={this.state.drawer.add}
            data={this.state.drawer.data}
            fetch={this.fetchQuotes}
            close={this.closeQuoteDrawer}
            clear={this.clearQuoteDrawer}
          />
        </Layout.Content>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
});

export default connect(mapStateToProps, {})(Quotes);
