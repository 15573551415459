import React, { Component } from "react";
import { Fade, Container } from "react-bootstrap";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Card,
  Button,
  Collapse,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Space,
  List,
  Popconfirm,
  notification,
} from "antd";
import {
  LoadingOutlined,
  MenuOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import $ from "jquery";
import { setActivePortfolioTab } from "../../actions";

import { API_ROOT } from "../../common";

import AddSectionDrawer from "./components/AddSectionDrawer";
import AddPortfolioDrawer from "./components/AddPortfolioDrawer";

class Portfolio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      progress: false,
      fetched: false,
      nav: this.props.tab,
      professional: [],
      personal: [],
      drawer: {
        section: false,
        portfolio: {
          section: {},
          show: false,
        },
      },
    };
  }

  componentDidMount() {
    this.fetchPortfolioSections();
  }

  componentDidUpdate() {
    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }

    if (this.state.nav !== this.props.tab) {
      this.setState({ ...this.state, nav: this.props.tab });
    }
  }

  fetchPortfolioSections = () =>
    $.ajax({
      url: API_ROOT + "/rest/portfolio-sections/",
      method: "GET",
      headers: { Authorization: this.state.access },
      success: async (resp) => {
        let { professional, personal } = this.state;
        let portfolio;

        professional = [];
        personal = [];

        if (resp.status) {
          for (let section of resp.results) {
            portfolio = await this.fetchPortfolio(section.id);

            if (portfolio && portfolio.status) {
              section.portfolio = portfolio.results;
            } else {
              if (portfolio) {
                notification.warning({
                  message: portfolio.error || portfolio.message,
                });
              }
            }

            if (section.section.value === 1) {
              professional.push(section);
            } else {
              personal.push(section);
            }
          }
        } else {
          notification.warning({ message: resp.error || resp.message });
        }

        this.setState({
          ...this.state,
          professional,
          personal,
          fetched: true,
          progress: false,
        });
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
        this.setState({ ...this.state, fetched: true, progress: false });
      },
    });

  fetchPortfolio = (section = null) =>
    $.ajax({
      url: API_ROOT + "/rest/portfolio/",
      method: "GET",
      headers: { Authorization: this.state.access },
      data: { section },
      success: (resp) => resp,
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

  getSections = () =>
    this.state.nav === "professional" ? (
      <React.Fragment>
        <Collapse accordion>
          {this.state.professional.map((section, index) => (
            <Collapse.Panel header={section.title} key={index}>
              <Form
                name={`section-form-${index}`}
                layout="vertical"
                fields={[
                  {
                    name: "title",
                    value: section.title,
                  },
                  {
                    name: "order",
                    value: section.order,
                  },
                  {
                    name: "description",
                    value: section.description,
                  },
                ]}
                onValuesChange={(e) => {
                  let { professional } = this.state;

                  let key = Object.keys(e)[0];

                  professional[index][key] = e[key];

                  this.setState({ ...this.state, professional });
                }}
                onFinish={() =>
                  this.setState({ ...this.state, progress: true }, () =>
                    this.submitSectionForm(section)
                  )
                }
              >
                <Row gutter={[5, 5]}>
                  <Col sm={4}>
                    <Form.Item
                      name="order"
                      label="Order"
                      rules={[
                        {
                          required: true,
                          message: "Enter Section Order!",
                        },
                      ]}
                    >
                      <InputNumber
                        min="0"
                        placeholder="Section Order"
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={20}>
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: "Enter Section Title!",
                        },
                      ]}
                    >
                      <Input placeholder="Section Title" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="description" label="Description">
                      <Input.TextArea
                        placeholder="Section Description"
                        rows="4"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Space wrap className="put-center">
                  <Button
                    type="primary"
                    ghost
                    onClick={() => this.openPortfolioDrawer(section)}
                  >
                    Add Portfolio
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={this.state.progress}
                    loading={this.state.progress}
                  >
                    Submit
                  </Button>
                  <Popconfirm
                    title="Delete this section?"
                    okText="Yes"
                    cancelText="No"
                    okType="danger"
                    onConfirm={() =>
                      this.setState({ ...this.state, progress: true }, () =>
                        this.deleteSection(section.id)
                      )
                    }
                    disabled={this.state.progress}
                  >
                    <Button
                      type="primary"
                      danger
                      disabled={this.state.progress}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                </Space>
                <hr />
                <List
                  dataSource={section.portfolio}
                  bordered
                  renderItem={(portfolio) => (
                    <List.Item>
                      <Link
                        className="w-100"
                        to={`/portfolio/${portfolio.id}/`}
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        <div className="put-between w-100">
                          <List.Item.Meta
                            title={portfolio.title}
                            description={portfolio.short_description}
                          />
                          <small>{portfolio.updated_at}</small>
                        </div>
                      </Link>
                    </List.Item>
                  )}
                />
              </Form>
            </Collapse.Panel>
          ))}
        </Collapse>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Collapse accordion>
          {this.state.personal.map((section, index) => (
            <Collapse.Panel header={section.title} key={index}>
              <Form
                name={`section-form-${index}`}
                layout="vertical"
                fields={[
                  {
                    name: "title",
                    value: section.title,
                  },
                  {
                    name: "order",
                    value: section.order,
                  },
                  {
                    name: "description",
                    value: section.description,
                  },
                ]}
                onValuesChange={(e) => {
                  let { personal } = this.state;

                  let key = Object.keys(e)[0];

                  personal[index][key] = e[key];

                  this.setState({ ...this.state, personal });
                }}
                onFinish={() =>
                  this.setState({ ...this.state, progress: true }, () =>
                    this.submitSectionForm(section)
                  )
                }
              >
                <Row gutter={[5, 5]}>
                  <Col sm={4}>
                    <Form.Item
                      name="order"
                      label="Order"
                      rules={[
                        {
                          required: true,
                          message: "Enter Section Order!",
                        },
                      ]}
                    >
                      <InputNumber
                        min="0"
                        placeholder="Section Order"
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={20}>
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: "Enter Section Title!",
                        },
                      ]}
                    >
                      <Input placeholder="Section Title" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="description" label="Description">
                      <Input.TextArea
                        placeholder="Section Description"
                        rows="4"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Space wrap className="put-center">
                  <Button
                    type="primary"
                    ghost
                    onClick={() => this.openPortfolioDrawer(section)}
                  >
                    Add Portfolio
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={this.state.progress}
                    loading={this.state.progress}
                  >
                    Submit
                  </Button>
                  <Popconfirm
                    title="Delete this section?"
                    okText="Yes"
                    cancelText="No"
                    okType="danger"
                    onConfirm={() =>
                      this.setState({ ...this.state, progress: true }, () =>
                        this.deleteSection(section.id)
                      )
                    }
                    disabled={this.state.progress}
                  >
                    <Button
                      type="primary"
                      danger
                      disabled={this.state.progress}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                </Space>
                <hr />
                <List
                  dataSource={section.portfolio}
                  bordered
                  renderItem={(portfolio) => (
                    <List.Item>
                      <Link
                        className="w-100"
                        to={`/portfolio/${portfolio.id}/`}
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        <div className="put-between w-100">
                          <List.Item.Meta
                            title={portfolio.title}
                            description={portfolio.short_description}
                          />
                          <small>{portfolio.updated_at}</small>
                        </div>
                      </Link>
                    </List.Item>
                  )}
                />
              </Form>
            </Collapse.Panel>
          ))}
        </Collapse>
      </React.Fragment>
    );

  submitSectionForm = (section) =>
    $.ajax({
      url: API_ROOT + `/rest/portfolio-sections/${section.id}/`,
      method: "PUT",
      headers: { Authorization: this.state.access },
      data: {
        title: section.title,
        order: section.order,
        description: section.description,
        section: this.state.nav === "professional" ? 1 : 2,
      },
      success: (resp) => {
        if (resp.status) {
          notification.success({ message: resp.message });
          this.fetchPortfolioSections();
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
        this.setState({ ...this.state, progress: false });
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
        this.setState({ ...this.state, progress: false });
      },
    });

  deleteSection = (id) =>
    $.ajax({
      url: API_ROOT + `/rest/portfolio-sections/${id}/`,
      method: "DELETE",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        if (resp.status) {
          notification.success({ message: resp.message });
        } else {
          notification.warning({ message: resp.error || resp.message });
        }

        this.fetchPortfolioSections();
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
        this.setState({ ...this.state, progress: false });
      },
    });

  openSectionDrawer = () => {
    let { drawer } = this.state;
    drawer.section = true;
    this.setState({ ...this.state, drawer });
  };

  closeSectionDrawer = () => {
    let { drawer } = this.state;
    drawer.section = false;
    this.setState({ ...this.state, drawer });
  };

  openPortfolioDrawer = (section) => {
    let { drawer } = this.state;
    drawer.portfolio.show = true;
    drawer.portfolio.section = section;
    this.setState({ ...this.state, drawer });
  };

  closePortfolioDrawer = () => {
    let { drawer } = this.state;
    drawer.portfolio.show = false;
    this.setState({ ...this.state, drawer });
  };

  clearPortfolioDrawer = () => {
    let { drawer } = this.state;
    drawer.portfolio.show = false;
    drawer.portfolio.section = {};
    this.setState({ ...this.state, drawer });
  };

  render() {
    return (
      <React.Fragment>
        <Layout.Header
          className={
            this.props.navCollapsed
              ? "ant-layout-header-full-width"
              : "ant-layout-header-calc-width"
          }
        >
          <PageHeader
            title="Portfolio"
            backIcon={<MenuOutlined className="trigger" />}
            onBack={this.props.toggleNavCollapse}
            extra={
              this.props.navCollapsed ||
              !window.matchMedia("(max-width: 576px)").matches ? (
                <Button
                  type="text"
                  onClick={() =>
                    this.setState(
                      {
                        ...this.state,
                        progress: true,
                      },
                      this.fetchPortfolioSections
                    )
                  }
                  disabled={this.state.progress || !this.state.fetched}
                >
                  {this.state.progress ? (
                    <LoadingOutlined />
                  ) : (
                    <ReloadOutlined />
                  )}
                </Button>
              ) : null
            }
          />
        </Layout.Header>
        <Layout.Content>
          <Breadcrumb>
            <Breadcrumb.Item>Portfolio</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <Fade appear={true} in={true}>
            <Container>
              {this.state.fetched ? (
                <Card
                  className="w-100"
                  tabList={[
                    {
                      key: "professional",
                      tab: "Professional",
                    },
                    {
                      key: "personal",
                      tab: "Personal",
                    },
                  ]}
                  activeTabKey={this.state.nav}
                  tabBarExtraContent={
                    <Button
                      type="primary"
                      ghost
                      onClick={this.openSectionDrawer}
                      disabled={!this.state.fetched && this.state.progress}
                    >
                      Add Section
                    </Button>
                  }
                  onTabChange={(key) => this.props.setActivePortfolioTab(key)}
                >
                  {this.getSections()}
                </Card>
              ) : (
                <div className="p-5 text-center">
                  <h5 className="put-center">
                    <LoadingOutlined className="mr-1" />
                    Fetching
                  </h5>
                </div>
              )}
            </Container>
          </Fade>
          <AddSectionDrawer
            show={this.state.drawer.section}
            fetch={this.fetchPortfolioSections}
            close={this.closeSectionDrawer}
            professionalLength={this.state.professional.length}
            personalLength={this.state.personal.length}
          />
          <AddPortfolioDrawer
            show={this.state.drawer.portfolio.show}
            section={this.state.drawer.portfolio.section}
            fetch={this.fetchPortfolioSections}
            close={this.closePortfolioDrawer}
            clear={this.clearPortfolioDrawer}
            history={this.props.history}
          />
        </Layout.Content>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
  tab: state.portfolio.tab,
});

export default connect(mapStateToProps, { setActivePortfolioTab })(Portfolio);
