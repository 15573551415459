import React, { Component } from "react";
import { Fade, Container } from "react-bootstrap";
import {
  Layout,
  PageHeader,
  Space,
  Button,
  Divider,
  Modal,
  Empty,
  Card,
  List,
  Upload,
  notification,
} from "antd";
import {
  MenuOutlined,
  LoadingOutlined,
  ReloadOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import $ from "jquery";

import { API_ROOT } from "../../common";

import TrafficChart from "./components/TrafficChart";
import Message from "../components/Message";

const dummyMessageData = {
  id: 0,
  name: "",
  email: "",
  message: "",
  read: false,
  summary: "",
  created_at: "",
  updated_at: "",
};

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      refresh: false,
      database: {
        progress: false,
      },
      stats: {
        fetched: false,
        traffic: null,
      },
      messages: [],
      drawer: {
        message: {
          show: false,
          data: JSON.parse(JSON.stringify(dummyMessageData)),
        },
      },
      upload: {
        disabled: false,
      },
    };
  }

  componentDidMount() {
    this.fetchTraffic();
    this.fetchMessages();
  }

  componentDidUpdate() {
    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }
  }

  fetchDatabase = () =>
    $.ajax({
      method: "GET",
      url: API_ROOT + "/rest/database/",
      headers: { Authorization: this.state.access },
      xhrFields: {
        responseType: "blob",
      },
      success: (resp) => {
        let { database } = this.state;
        database.progress = false;
        this.setState({ ...this.state, database });

        const url = window.URL.createObjectURL(resp);
        const a = document.createElement("a");

        a.style.display = "none";
        a.href = url;
        a.download = "backup.json";

        document.body.appendChild(a);
        a.click();

        notification.success({ message: "Database backup downloaded." });

        window.URL.revokeObjectURL(url);

        a.remove();
      },
      error: (err) => {
        console.log(err);
        let { database } = this.state;
        database.progress = false;
        this.setState({ ...this.state, database });

        notification.error({ message: "Couldn't fetch backup file." });
      },
    });

  fetchTraffic = () =>
    $.ajax({
      method: "GET",
      url: API_ROOT + "/rest/stats/traffic/",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        let { stats } = this.state;
        stats.fetched = true;

        if (resp.status) {
          stats.traffic = resp.results;
        } else {
          Modal.warning({ title: resp.error || resp.message });
        }

        this.setState({ ...this.state, stats });
      },
      error: (err) => {
        console.log(err);

        let { stats } = this.state;
        stats.fetched = true;
        this.setState({ ...this.state, stats });

        Modal.error({
          title: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong",
        });
      },
    });

  fetchMessages = () =>
    $.ajax({
      method: "GET",
      url: API_ROOT + "/rest/messages/",
      headers: { Authorization: this.state.access },
      data: { read: false },
      success: (resp) => {
        if (resp.status) {
          this.setState({ ...this.state, messages: resp.results });
        } else {
          Modal.warning({ title: resp.error || resp.message });
        }
      },
      error: (err) => {
        console.log(err);

        Modal.error({
          title: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong",
        });
      },
    });

  openMessageDrawer = (data) => {
    let { drawer } = this.state;
    drawer.message.show = true;
    drawer.message.data = JSON.parse(JSON.stringify(data));

    $.ajax({
      url: API_ROOT + "/rest/messages/",
      method: "POST",
      headers: { Authorization: this.props.access },
      data: {
        messages: JSON.stringify([data.id]),
      },
      success: (resp) => {
        if (!resp.status) {
          notification.warning({ message: resp.error || resp.message });
        }
      },
      error: (err) => {
        console.log(err);

        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });

    this.setState({ ...this.state, drawer });
  };

  closeMessageDrawer = () => {
    let { drawer } = this.state;
    drawer.message.show = false;
    this.setState({ ...this.state, drawer });
  };

  clearMessageDrawer = () => {
    let { drawer } = this.state;
    drawer.message.show = false;
    drawer.message.data = JSON.parse(JSON.stringify(dummyMessageData));
    this.fetchMessages();
    this.setState({ ...this.state, drawer });
  };

  render() {
    return (
      <React.Fragment>
        <Layout.Header
          className={
            this.props.navCollapsed
              ? "ant-layout-header-full-width"
              : "ant-layout-header-calc-width"
          }
        >
          <PageHeader
            title="Home"
            backIcon={<MenuOutlined className="trigger" />}
            onBack={this.props.toggleNavCollapse}
            extra={
              this.props.navCollapsed ||
              !window.matchMedia("(max-width: 576px)").matches ? (
                <Button
                  type="text"
                  onClick={() =>
                    this.setState({ ...this.state, refresh: true }, () => {
                      this.fetchTraffic();
                      this.fetchMessages();
                      this.setState({ ...this.state, refresh: false });
                    })
                  }
                  // loading={this.state.stats.refresh}
                  disabled={this.state.stats.refresh}
                >
                  {!this.state.stats.refresh ? (
                    <ReloadOutlined />
                  ) : (
                    <LoadingOutlined />
                  )}
                </Button>
              ) : null
            }
          />
        </Layout.Header>
        <Layout.Content>
          <Fade appear={true} in={true}>
            <Container>
              <Divider>Links</Divider>
              <Space className="put-center" wrap direction="horizontal">
                <Button type="link" target="blank" href={API_ROOT}>
                  Legacy Dashboard
                </Button>
                <Button type="link" target="blank" href={`${API_ROOT}/admin`}>
                  Admin
                </Button>
                <Button
                  type="link"
                  target="blank"
                  href="https://satshree.com.np/#/?no_call=1"
                >
                  Website
                </Button>
                <Button
                  type="link"
                  target="blank"
                  href="https://bitbucket.org/satshree/workspace/projects/WEB"
                >
                  Git
                </Button>
              </Space>
              <Divider>New Messages</Divider>
              <Card className="w-100">
                {this.state.messages.length === 0 ? (
                  <Empty description="No New Messages" />
                ) : (
                  <List
                    bordered
                    dataSource={this.state.messages}
                    renderItem={(message) => (
                      <List.Item
                        className="clickable bg-unread"
                        onClick={() => this.openMessageDrawer(message)}
                      >
                        <div className="put-between w-100">
                          <List.Item.Meta
                            title={message.summary}
                            description={message.email}
                          />
                          <small>{message.created_at}</small>
                        </div>
                      </List.Item>
                    )}
                  />
                )}
              </Card>
              <Divider>Traffic</Divider>
              <div>
                {this.state.stats.fetched ? (
                  this.state.stats.traffic ? (
                    <TrafficChart data={this.state.stats.traffic} />
                  ) : (
                    <div className="p-5 text-center">
                      <Empty description="No Traffic Data" />
                    </div>
                  )
                ) : (
                  <div className="p-5 text-center">
                    <h5 className="put-center">
                      <LoadingOutlined className="mr-2" />
                      Fetching
                    </h5>
                  </div>
                )}
              </div>
              <Divider>Database</Divider>
              <Space className="put-center" wrap direction="horizontal">
                <Button
                  type="primary"
                  onClick={() => {
                    let { database } = this.state;
                    database.progress = true;
                    this.setState(
                      { ...this.state, database },
                      this.fetchDatabase
                    );
                  }}
                  loading={this.state.database.progress}
                  disabled={this.state.database.progress}
                >
                  {!this.state.database.progress ? <DownloadOutlined /> : null}
                  Download Database Backup
                </Button>
                <Upload
                  name="file"
                  className="ml-auto mr-auto"
                  action={API_ROOT + "/rest/database/"}
                  headers={{ Authorization: this.props.access }}
                  accept=".json"
                  onChange={(info) => {
                    const { status } = info.file;
                    let { upload } = this.state;
                    upload.disabled = true;

                    // if (status !== "uploading") {
                    //   console.log(info.file, info.fileList);
                    // }

                    if (status === "done") {
                      notification.success({ message: "Database Restored!" });

                      upload.disabled = false;

                      this.props.logUserOut();
                    } else if (status === "error") {
                      notification.error({
                        message: "Database restore failed.",
                      });

                      upload.disabled = false;
                    }

                    this.setState({ ...this.state, upload });
                  }}
                  showUploadList={false}
                  disabled={this.state.upload.disabled}
                >
                  <Button
                    type="primary"
                    ghost
                    loading={this.state.upload.disabled}
                    disabled={this.state.upload.disabled}
                  >
                    {!this.state.upload.disabled ? <UploadOutlined /> : null}
                    Restore Database Backup
                  </Button>
                </Upload>
              </Space>
            </Container>
          </Fade>
          <Message
            show={this.state.drawer.message.show}
            data={this.state.drawer.message.data}
            close={this.closeMessageDrawer}
            clear={this.clearMessageDrawer}
          />
        </Layout.Content>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
});

export default connect(mapStateToProps, {})(Home);
