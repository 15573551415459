import { Drawer, Button, Row, Col, Space } from "antd";

export default function HourlyDetailDrawer(props) {
  let raw = JSON.stringify(JSON.parse(props.data.raw), undefined, 2);

  return (
    <Drawer
      title={`Hourly Detail of ${props.ip} at ${props.time}`}
      visible={props.show}
      height={window.matchMedia("(max-width: 576px)").matches ? "100%" : "80%"}
      placement="bottom"
      onClose={props.close}
      afterVisibleChange={(visible) => {
        if (!visible) props.clear();
      }}
      footer={
        <div className="text-center">
          <Button type="default" onClick={props.close}>
            Close
          </Button>
        </div>
      }
    >
      <Row gutter={[5, 5]}>
        <Col span={24}>
          <Space direction="vertical">
            <div>
              <b>ID</b>
              <br />
              {props.data.id}
            </div>
            <div>
              <b>IP Address</b>
              <br />
              <p className="p-2">{props.ip}</p>
            </div>
            <div>
              <b>User Agent</b>
              <br />
              <p
                className="p-2"
                style={{ wordBreak: "break-all" }}
                align="justify"
              >
                {props.data.user_agent}
              </p>
            </div>
            <div>
              <b>Header</b>
              <br />
              <p
                className="p-2"
                style={{ wordBreak: "break-all" }}
                align="justify"
              >
                {props.data.header}
              </p>
            </div>
            <div>
              <b>Requested With</b>
              <br />
              <p className="p-2">{props.data.request_with}</p>
            </div>
            <div>
              <b>API</b>
              <br />
              <p className="p-2">{props.data.api}</p>
            </div>
          </Space>
        </Col>
        <Col span={24}>
          <b>Raw</b>
          <br />
          <pre className="p-4">{raw}</pre>
        </Col>
      </Row>
    </Drawer>
  );
}
