import React, { Component } from "react";
import {
  Drawer,
  Space,
  Form,
  Button,
  Input,
  InputNumber,
  Select,
  Row,
  Col,
  notification,
} from "antd";
import { connect } from "react-redux";
import $ from "jquery";

import { API_ROOT } from "../../../common";

class AddSectionDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: false,
      data: {
        show: this.props.show,
        title: "",
        order: "",
        description: "",
        section: null,
      },
    };

    this.form = React.createRef();
  }

  componentDidUpdate() {
    if (this.state.show !== this.props.show) {
      this.setState({ ...this.state, show: this.props.show });
    }
  }

  submitForm = () =>
    $.ajax({
      url: API_ROOT + "/rest/portfolio-sections/",
      method: "POST",
      headers: { Authorization: this.props.access },
      data: {
        title: this.state.data.title,
        order: this.state.data.order,
        description: this.state.data.description,
        section: this.state.data.section,
      },
      success: (resp) => {
        if (resp.status) {
          this.props.close();

          notification.success({ message: resp.message });
          let { data } = this.state;

          data.title = "";
          data.order = "";
          data.description = "";
          data.section = null;

          this.setState({ ...this.state, data });

          this.props.fetch();
          this.props.close();
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
        this.setState({ ...this.state, progress: false });
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
        this.setState({ ...this.state, progress: false });
      },
    });

  render() {
    return (
      <Drawer
        title="Add New Section"
        visible={this.state.show}
        closable={!this.state.progress}
        keyboard={!this.state.progress}
        maskClosable={!this.state.progress}
        height={window.matchMedia("(max-width: 576px)").matches ? "50%" : "40%"}
        placement="bottom"
        onClose={this.props.close}
        afterVisibleChange={(visible) => {
          if (!visible) this.form.current.resetFields();
        }}
        footer={
          <Space className="put-center">
            <Button
              type="primary"
              onClick={() => $("#add-section-form-btn").trigger("click")}
              loading={this.state.progress}
              disabled={this.state.progress}
            >
              Submit
            </Button>
          </Space>
        }
      >
        <Form
          name="add-section-form"
          ref={this.form}
          layout="vertical"
          fields={[
            {
              name: "title",
              value: this.state.data.title,
            },
            {
              name: "order",
              value: this.state.data.order,
            },
            {
              name: "description",
              value: this.state.data.description,
            },
            {
              name: "section",
              value: this.state.data.section,
            },
          ]}
          onValuesChange={(e) => {
            let { data } = this.state;

            let key = Object.keys(e)[0];

            data[key] = e[key];

            this.setState({ ...this.state, data });
          }}
          onFinish={() =>
            this.setState({ ...this.state, progress: true }, this.submitForm)
          }
        >
          <button
            type="submit"
            id="add-section-form-btn"
            style={{ display: "none" }}
          />
          <Row gutter={[5, 5]}>
            <Col sm={4}>
              <Form.Item
                name="order"
                label="Order"
                rules={[
                  {
                    required: true,
                    message: "Enter Section Order!",
                  },
                ]}
              >
                <InputNumber
                  min="0"
                  placeholder={
                    this.state.data.section
                      ? this.state.data.section === 1
                        ? this.props.professionalLength + 1
                        : this.props.personalLength + 1
                      : "Section Order"
                  }
                  className="w-100"
                />
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item
                name="section"
                label="Section"
                rules={[
                  {
                    required: true,
                    message: "Select Section Type!",
                  },
                ]}
              >
                <Select placeholder="Section Type" className="w-100">
                  <Select.Option value={1}>Professional</Select.Option>
                  <Select.Option value={2}>Personal</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Enter Section Title!",
                  },
                ]}
              >
                <Input placeholder="Section Title" className="w-100" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="description" label="Description">
                <Input.TextArea placeholder="Section Description" rows="3" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  access: `Bearer ${state.auth.tokens.access}`,
});

export default connect(mapStateToProps, {})(AddSectionDrawer);
