import React, { Component } from "react";
import {
  Drawer,
  Form,
  Input,
  Button,
  Space,
  Row,
  Col,
  Popconfirm,
  DatePicker,
  notification,
} from "antd";
import { connect } from "react-redux";
import $ from "jquery";
import moment from "moment";

import { API_ROOT } from "../../../common";

class ExperienceDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      progress: false,
      show: this.props.show,
      data: this.props.data,
    };

    this.form = React.createRef();
  }

  componentDidUpdate() {
    if (this.state.data !== this.props.data) {
      this.setState({ ...this.state, data: this.props.data });
    }

    if (this.state.show !== this.props.show) {
      this.setState({ ...this.state, show: this.props.show });
    }

    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }
  }

  submitForm = () => {
    this.setState({ ...this.state, progress: true });

    let { data } = this.state;
    let url;
    let method;

    if (this.props.add) {
      url = API_ROOT + "/rest/experience/";
      method = "POST";
    } else {
      url = API_ROOT + `/rest/experience/${data.id}/`;
      method = "PUT";
    }

    $.ajax({
      url,
      method,
      headers: { Authorization: this.state.access },
      data: {
        order: data.order,
        title: data.title,
        employer: data.employer,
        description: data.description,
        location: data.location,
        start_date: data.start_date.format("YYYY-MM-DD"),
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : "",
      },
      success: (resp) => {
        this.setState({ ...this.state, progress: false });

        if (resp.status) {
          notification.success({ message: resp.message });
          this.props.fetch();
          if (this.props.add) {
            this.props.close();
          }
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
      },
      error: (err) => {
        console.log(err);
        this.setState({ ...this.state, progress: false });
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });
  };

  deleteExperience = () => {
    this.setState({ ...this.state, progress: true });

    let { data } = this.state;

    $.ajax({
      url: API_ROOT + `/rest/experience/${data.id}/`,
      method: "DELETE",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        this.setState({ ...this.state, progress: false });

        if (resp.status) {
          notification.success({ message: resp.message });
          this.props.close();
          this.props.fetch();
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
      },
      error: (err) => {
        console.log(err);
        this.setState({ ...this.state, progress: false });
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
      },
    });
  };

  render() {
    return (
      <Drawer
        title={
          this.props.add
            ? "Add New Experience"
            : `${this.state.data.title} (${this.state.data.employer})`
        }
        open={this.state.show}
        closable={!this.state.progress}
        keyboard={!this.state.progress}
        maskClosable={!this.state.progress}
        height={window.matchMedia("(max-width: 576px)").matches ? "80%" : "65%"}
        placement="bottom"
        onClose={this.props.close}
        afterOpenChange={(visible) => {
          if (!visible) {
            this.props.clear();
            this.form.current.resetFields();
          }
        }}
        footer={
          <Space className="put-center">
            <Button
              type="primary"
              onClick={() => $("#experience-form-btn").trigger("click")}
              loading={this.state.progress}
              disabled={this.state.progress}
            >
              Submit
            </Button>
            {!this.props.add ? (
              <Popconfirm
                title="Delete this experience?"
                placement="top"
                onConfirm={this.deleteExperience}
                okText="Yes"
                cancelText="No"
                okType="danger"
                disabled={this.state.progress}
              >
                <Button type="primary" danger disabled={this.state.progress}>
                  Delete
                </Button>
              </Popconfirm>
            ) : null}
          </Space>
        }
      >
        <Form
          name="education-form"
          ref={this.form}
          layout="vertical"
          fields={[
            {
              name: "title",
              value: this.state.data.title,
            },
            {
              name: "employer",
              value: this.state.data.employer,
            },
            {
              name: "description",
              value: this.state.data.description,
            },
            {
              name: "location",
              value: this.state.data.location,
            },
            {
              name: "start_date",
              value: this.state.data.start_date,
            },
            {
              name: "end_date",
              value: this.state.data.end_date,
            },
            {
              name: "order",
              value: this.state.data.order,
            },
          ]}
          onValuesChange={(e) => {
            let { data } = this.state;

            let key = Object.keys(e)[0];

            if (key === "start_date" || (key === "end_date" && e[key])) {
              data[key] = moment(
                `${e[key]._d.getFullYear()}-${String(
                  e[key]._d.getMonth() + 1
                ).padStart(2, "0")}-${String(e[key]._d.getDate()).padStart(
                  2,
                  "0"
                )}`,
                "YYYY-MM-DD"
              );
            } else {
              data[key] = e[key];
            }

            this.setState({ ...this.state, data });
          }}
          onFinish={this.submitForm}
        >
          <button
            style={{ display: "none" }}
            type="submit"
            id="experience-form-btn"
          ></button>
          <Row gutter={[5, 5]}>
            <Col sm={2}>
              <Form.Item
                label="Order"
                name="order"
                rules={[
                  {
                    required: true,
                    message: "Enter Experience Order!",
                  },
                ]}
              >
                <Input placeholder="Order" type="number" />
              </Form.Item>
            </Col>
            <Col sm={11}>
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Enter Job Title!",
                  },
                ]}
              >
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col sm={11}>
              <Form.Item
                label="Employer"
                name="employer"
                rules={[
                  {
                    required: true,
                    message: "Enter Employer Name!",
                  },
                ]}
              >
                <Input placeholder="Employer" />
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item
                label="Location"
                name="location"
                rules={[
                  {
                    required: true,
                    message: "Enter School Name!",
                  },
                ]}
              >
                <Input placeholder="School" />
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[
                  {
                    required: true,
                    message: "Enter Start Date!",
                  },
                ]}
              >
                <DatePicker
                  className="w-100"
                  placeholder="Start Date"
                  format="YYYY-MM-DD"
                  showTime
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item name="end_date" label="End Date">
                <DatePicker
                  className="w-100"
                  placeholder="End Date"
                  format="YYYY-MM-DD"
                  showTime
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item name="description" label="Description">
                <Input.TextArea
                  placeholder="Experience Description"
                  rows="5"
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
});

export default connect(mapStateToProps, {})(ExperienceDrawer);
