import { Column } from "@ant-design/charts";

export default function WebsiteChart(props) {
  let data = props.data;

  let config = {
    data,
    xField: "date",
    yField: "count",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  return <Column {...config} />;
}
