import React, { Component } from "react";
import { Fade, Container } from "react-bootstrap";
import {
  Layout,
  PageHeader,
  Form,
  Input,
  Row,
  Col,
  Button,
  Space,
  notification,
} from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import $ from "jquery";

import { API_ROOT } from "../../common";

const dummyData = {
  id: 0,
  first_name: "",
  last_name: "",
  username: "",
  email: "",
  is_superuser: false,
  is_staff: false,
  is_active: false,
  last_login: "",
  date_joined: "",
};

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: this.props.access,
      fetched: false,
      progress: false,
      data: JSON.parse(JSON.stringify(dummyData)),
      password: {
        password: "",
        confirm: "",
      },
    };
  }

  componentDidMount() {
    this.fetchDetail();
  }

  componentDidUpdate() {
    if (this.state.access !== this.props.access) {
      this.setState({ ...this.state, access: this.props.access });
    }
  }

  fetchDetail = () =>
    $.ajax({
      url: API_ROOT + "/rest/self/",
      method: "GET",
      headers: { Authorization: this.state.access },
      success: (resp) => {
        let { data } = this.state;
        if (resp.status) {
          data = resp.results;
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
        this.setState({ ...this.state, data, progress: false, fetched: true });
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
        this.setState({ ...this.state, progress: false, fetched: true });
      },
    });

  submitForm = () =>
    $.ajax({
      url: API_ROOT + "/rest/self/",
      method: "POST",
      headers: { Authorization: this.state.access },
      data: {
        first_name: this.state.data.first_name,
        last_name: this.state.data.last_name,
        email: this.state.data.email,
        username: this.state.data.username,
        password: this.state.password.password,
      },
      success: (resp) => {
        if (resp.status) {
          notification.success({
            message: resp.message,
            password: {
              password: "",
              confirm: "",
            },
          });
        } else {
          notification.warning({ message: resp.error || resp.message });
        }
        this.setState({ ...this.state, progress: false, fetched: true });
      },
      error: (err) => {
        console.log(err);
        notification.error({
          message: err.responseJSON
            ? err.responseJSON.error || err.responseJSON.message
            : "Something went wrong.",
        });
        this.setState({ ...this.state, progress: false, fetched: true });
      },
    });

  render() {
    return (
      <React.Fragment>
        <Layout.Header
          className={
            this.props.navCollapsed
              ? "ant-layout-header-full-width"
              : "ant-layout-header-calc-width"
          }
        >
          <PageHeader
            title="Profile"
            backIcon={<MenuOutlined className="trigger" />}
            onBack={this.props.toggleNavCollapse}
          />
        </Layout.Header>
        <Layout.Content>
          <Fade appear={true} in={true}>
            <Container>
              <Form
                name="user-form"
                layout="vertical"
                fields={[
                  {
                    name: "first_name",
                    value: this.state.data.first_name,
                  },
                  {
                    name: "last_name",
                    value: this.state.data.last_name,
                  },
                  {
                    name: "email",
                    value: this.state.data.email,
                  },
                  {
                    name: "username",
                    value: this.state.data.username,
                  },
                  {
                    name: "password",
                    value: this.state.password.password,
                  },
                  {
                    name: "confirm",
                    value: this.state.password.confirm,
                  },
                ]}
                onValuesChange={(e) => {
                  let { data, password } = this.state;

                  let key = Object.keys(e)[0];

                  if (key === "password" || key === "confirm") {
                    password[key] = e[key];
                  } else {
                    data[key] = e[key];
                  }

                  this.setState({ ...this.state, data, password });
                }}
                onFinish={() =>
                  this.setState(
                    { ...this.state, progress: true },
                    this.submitForm
                  )
                }
              >
                <Row gutter={[5, 5]}>
                  <Col sm={6}>
                    <Form.Item
                      name="first_name"
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          message: "Enter First Name!",
                        },
                      ]}
                      disabled={!this.state.fetched}
                    >
                      <Input placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col sm={6}>
                    <Form.Item
                      name="last_name"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: "Enter Last Name!",
                        },
                      ]}
                      disabled={!this.state.fetched}
                    >
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                  <Col sm={6}>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          required: true,
                          message: "Enter Email!",
                        },
                      ]}
                      disabled={!this.state.fetched}
                    >
                      <Input placeholder="Email" type="email" />
                    </Form.Item>
                  </Col>
                  <Col sm={6}>
                    <Form.Item
                      name="username"
                      label="Username"
                      rules={[
                        {
                          required: true,
                          message: "Enter Username!",
                        },
                      ]}
                      disabled={!this.state.fetched}
                    >
                      <Input placeholder="Username" />
                    </Form.Item>
                  </Col>
                </Row>
                <hr />
                <Row gutter={[5, 5]}>
                  <Col sm={12}>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: this.state.password.confirm !== "",
                          message: "Enter Password!",
                        },
                      ]}
                      disabled={!this.state.fetched}
                    >
                      <Input.Password placeholder="Password" />
                    </Form.Item>
                  </Col>
                  <Col sm={12}>
                    <Form.Item
                      name="confirm"
                      label="Confirm Password"
                      rules={[
                        {
                          required: this.state.password.password !== "",
                          message: "Confirm Password!",
                        },
                      ]}
                      disabled={!this.state.fetched}
                    >
                      <Input.Password placeholder="Confirm Password" />
                    </Form.Item>
                  </Col>
                </Row>
                <Space wrap className="put-center">
                  <Button
                    type="primary"
                    ghost
                    disabled={!this.state.fetched || this.state.progress}
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        password: {
                          password: "",
                          confirm: "",
                        },
                      })
                    }
                  >
                    Clear Password
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !this.state.fetched ||
                      this.state.password.password !==
                        this.state.password.confirm ||
                      this.state.progress
                    }
                    loading={this.state.progress}
                  >
                    Submit
                  </Button>
                </Space>
              </Form>
            </Container>
          </Fade>
        </Layout.Content>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  access: "Bearer " + state.auth.tokens.access,
});

export default connect(mapStateToProps, {})(Profile);
